import { render, staticRenderFns } from "./Implant.vue?vue&type=template&id=1f141942&scoped=true&"
import script from "./Implant.vue?vue&type=script&lang=js&"
export * from "./Implant.vue?vue&type=script&lang=js&"
import style0 from "./Implant.vue?vue&type=style&index=0&id=1f141942&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f141942",
  null
  
)

export default component.exports