<template>
  <!--  其他文件列表Drawer  -->

  <drawer
    :visible.sync="openFilesList"
    :width="720"
    title="其他文件"
    cancel-str="关闭"
    :show-confirm="false"
    @cancel="closeFilesListDrawer">
    <div class="files">
      <a :href="i.path" target="_blank" class="files-item" v-for="(i, k) in filesList" :key="k">
        <div class="item-info">
          <div class="item-info__icon">
            <img v-if="i.fileFormat === 'pdf'" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-pdf.png" alt="">
            <img v-if="['doc', 'docx'].indexOf(i.fileFormat) !== -1" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-word.png" alt="">
            <img v-if="['xls', 'xlsx'].indexOf(i.fileFormat) !== -1" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-excel.png" alt="">
            <img v-if="['png', 'jpg', 'jpeg'].indexOf(i.fileFormat) !== -1" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-picture.png" alt="">
          </div>
          <div class="item-info__desc">
            <div class="desc-title">{{i.filename}}.{{i.fileFormat}}</div>
            <div class="desc-size">{{i.sizeFormat}}</div>
          </div>
        </div>
        <div class="item-preview"></div>
      </a>
    </div>
  </drawer>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer'
import { getFileList } from '@/service/module/followup'

export default {
  components: {
    Drawer
  },
  data () {
    return {
      filesList: [],
      openFilesList: false
    }
  },
  methods: {
    async openFilesListDrawer () {
      this.openFilesList = true
      const [err, res] = await this.$to(getFileList(97))
      if (res && res.code === 200) {
        this.filesList = res.data
      } else {
        this.$message.error('网络错误')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    closeFilesListDrawer () {
      this.openFilesList = false
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../style/common";
  .files {
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #DCDFE6;
      border-radius: 4px;
      margin-bottom: 8px;
      padding: 0 12px;
      cursor: pointer;
      @include domSize(672px, 60px);
      .item-info {
        display: flex;
        &__icon {
          @include domSize(36px, 36px);
          margin-right: 8px;
          img {
            @include domSize(100%, 100%);
          }
        }
        &__desc {
          .desc-title {
            @include fs(#131414, 14px, 400);
          }
          .desc-size {
            @include fs(#606266, 12px, 400);
          }
        }
      }
      .item-preview {
        background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-preview.svg") no-repeat;
        background-size: 100% 100%;
        @include domSize(12.83px, 9.92px);
      }
    }
}
</style>
