<template>
  <!--  心理量表列表Drawer  -->
  <div class="psychological" v-if="psychologicalList.length > 0">
    <el-scrollbar  style="height: 100%">
      <div class="psychological-list">
        <div class="psychological-item" v-for="(i, k) in psychologicalList" :key="k" @click="openPsychologicalDetailDrawer(i)">
          <div class="psychological-item__bg">
            <div class="psychological-item__bg--score">{{i.score}}分</div>
          </div>
          <div class="psychological-item__desc">
            <div class="desc-title">{{i.name}}</div>
            <div class="desc-date">{{moment.unix(i.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <drawer
        :visible.sync="openPsychologicalDetail"
        :wrapper-closable="true"
        :close-on-press-escape="true"
        :width="440"
        :show-cancel="false"
        :show-confirm="false"
        :title="psychologicalDetailTitle"
        :to-body="true"
        :has-footer="false"
        @cancel="closePsychologicalDetailDrawer">
        <div class="topics">
          <div class="topics-item" v-for="(item, index) in listOfTopics" :key="index">
            <div class="topics-item__question">{{index + 1}}、{{item.label}}</div>
            <div class="topics-item__answer">
              <el-radio-group v-model="item.choose">
                <el-radio :disabled="item.choose !== k" v-for="(child, k) in item.opts" :key="k" :label="k">{{child.text}}</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </drawer>
  </div>
  <Empty v-else class="empty" text="暂无记录" :show-img="true"></Empty>
<!--  <drawer-->
<!--    :visible.sync="openPsychologicalList"-->
<!--    :width="720"-->
<!--    title="心理量表"-->
<!--    cancel-str="关闭"-->
<!--    :show-confirm="false"-->
<!--    @cancel="closePsychologicalListDrawer">-->
<!--    <div class="psychological">-->
<!--      <div class="psychological-list">-->
<!--        <div class="psychological-item" v-for="(i, k) in psychologicalList" :key="k" @click="openPsychologicalDetailDrawer(i)">-->
<!--          <div class="psychological-item__bg">-->
<!--            <div class="psychological-item__bg&#45;&#45;score">{{i.score}}分</div>-->
<!--          </div>-->
<!--          <div class="psychological-item__desc">-->
<!--            <div class="desc-title">{{i.name}}</div>-->
<!--            <div class="desc-date">{{moment.unix(i.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--  </drawer>-->
</template>

<script>
import Drawer from '@/components/Drawer/Drawer'
import Empty from '@/components/Empty'
import { getPsycScale, getPsycScaleDetail } from '@/service/module/followup'
const moment = require('moment')

export default {
  components: {
    Empty,
    Drawer
  },
  props: {
    patientId: [Number, String]
  },
  data () {
    return {
      moment: moment,
      listOfTopics: [],
      psychologicalList: [],
      psychologicalDetailTitle: '',
      openPsychologicalList: false,
      openPsychologicalDetail: false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const params = {
        patientId: this.patientId,
        pageNum: 1,
        pageSize: 100
      }
      const [err, res] = await this.$to(getPsycScale(params))
      if (res && res.code === 200) {
        this.psychologicalList = res.data.results || []
      } else {
        this.$message.error('网络错误')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    closePsychologicalListDrawer () {
      this.openPsychologicalList = false
    },
    async openPsychologicalDetailDrawer (item) {
      this._send('$click', '心理量表查看点击', '心理量表查看按钮 发生点击行为触发', {
        accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
        doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
        hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
      })
      const [err, res] = await this.$to(getPsycScaleDetail(item.id))
      console.log('详情：', res)
      if (res && res.code === 200) {
        this.listOfTopics = res.data.data
        this.openPsychologicalDetail = true
        this.psychologicalDetailTitle = item.name
      } else {
        this.$message.error('网络错误')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    closePsychologicalDetailDrawer () {
      this.openPsychologicalDetail = false
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../../../style/common";
.psychological {
  padding: 16px 16px 0;
  flex: 1;
  overflow: hidden;
    &-list {
      display: flex;
      flex-wrap: wrap;
    }
    &-item {
      margin: 0 24px 24px 0;
      display: flex;
      flex-direction: column;
      border: 1px solid #EBEEF5;
      border-radius: 4px;
      @include domSize(208px, 264px);
      &__bg {
        /*background: linear-gradient(119.81deg, rgba(0, 209, 197, 0.07) 10.08%, rgba(0, 209, 197, 0) 97.46%);*/
        background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/bg-psychological.png") no-repeat;
        background-size: 100% 100%;
        @include domSize(100%, 186.15px);
        &--score {
          text-align: center;
          padding-top: 130px;
          @include fs(#a4cac5, 24px, 500);
        }
      }
      &__desc {
        flex: 1;
        padding: 14.07px;
        .desc-title {
          margin-bottom: 3.5px;
          @include fs(#303133, 16px, 500);
        }
        .desc-date {
          @include fs(#606266, 14px, 400);
        }
      }
    }
}
.topics {
  &-item {
    margin-bottom: 16px;
    &__question {
      margin-bottom: 8px;
      line-height: 24px;
      @include fs(#131414, 14px, 400);
    }
  }
  ::v-deep .el-radio {
    margin-right: 24px;
  }
}

.empty {
  margin-top: 120px;
}
</style>
