<template>
  <div>
    <table class="form-comp" style="border-collapse: collapse; border: 1px #E4E7ED; width: 100%;">
      <tr class="comp-header ">
        <td class="comp-row" style="width: 35%">
          <div class="comp-cell">指标</div>
        </td>
        <td class="comp-row" style="width: 28%">
          <div class="comp-cell">结果</div>
        </td>
        <td class="comp-row" style="width: 14%">
          <div class="comp-cell">
            单位
          </div>
        </td>
        <td class="comp-row" style="width: 23%">
          <div class="comp-cell">操作</div>
        </td>
      </tr>
    </table>
    <Item :report-id="reportId" :form-data="formData"></Item>
  </div>
</template>

<script>
import Item from './CompleteItem'
export default {
  name: 'CompleteForm',
  components: {
    Item
  },
  props: {
    formData: {
      type: Array,
      default: () => []
    },
    reportId: [Number, String]
  },
  mounted () {
    console.log(this.formData)
  }
}
</script>

<style scoped lang="scss">
  .form-comp {
    .comp-header {
      height: 44px;
      background: #F2F6FC;
    }
    .comp-row {
      border: 1px solid #E4E7ED;
      border-top: none;
      padding: 12px 16px;
      vertical-align: middle;
      &:first-of-type {
        border-left: none;
      }
      &:last-of-type {
        border-right: none;
      }
    }
    .empty-row {
      border-bottom: 1px solid #E4E7ED;
      &:last-of-type {
        border-right: 1px solid #E4E7ED;
      }
      padding: 12px 16px;
    }
    .comp-title {
      .comp-row {
        border-right: none;
      }
    }
    .comp-single {
    }
    .comp-cell {
      word-break: break-all;
    }
    .border-except-top {
      border: 1px solid #E4E7ED;
      border-top: none;
    }
  }
</style>
