<template>
  <div class="patient">
    <div class="view">
      <el-descriptions title="" :column="3">
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="患者姓名">{{viewData.name}}</el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="性别">{{genderStr}}</el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="病历号">{{viewData.recordNo}}</el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="注册手机">{{viewData.phone}}</el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="年龄">{{viewData.age}}</el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="身份证">{{viewData.certificateNo}}</el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="常驻地">{{viewData.province}} {{viewData.city}} {{viewData.district}}</el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="文化程度">{{eduStr}}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions>
        <el-descriptions-item :span="3" label-class-name="my-label" content-class-name="my-content" label="基础疾病">{{diseaseStr}}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="btn-wrap">
      <el-button v-if="editPatientArchiveHandle" size="small" type="primary" plain @click="openPatientEditDrawer">编辑</el-button>
    </div>
    <drawer
      :visible.sync="openPatientEdit"
      :width="400"
      :to-body="true"
      title="编辑患者信息"
      cancel-str="取消"
      confirm-str="保存"
      @cancel="closePatientEditDrawer"
      @confirm="savePatientInfo">
      <div class="edit">
        <el-form :model="fixedForm" ref="formRules" :rules="formRules" label-width="80px">
          <el-form-item label="患者姓名" prop="name">
            <el-input v-model="fixedForm.name" size="small" :style="fixStyle" placeholder="请填写患者姓名"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="age">
            <el-select v-model="fixedForm.gender" size="small" :style="fixStyle" placeholder="请选择患者性别">
              <el-option v-for="(i, k) in genderEnums" :key="k" :label="i.text" :value="i.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="病历号" prop="recordNo">
            <el-input v-model="fixedForm.recordNo" size="small" :style="fixStyle" placeholder="请填写患者病历号"></el-input>
          </el-form-item>
          <el-form-item label="常驻地">
            <el-cascader v-model="addressLabel" :options="regions" size="small" :show-all-levels="false" :style="fixStyle" placeholder="请选择患者常驻地"></el-cascader>
          </el-form-item>
          <el-form-item label="文化程度">
            <el-select v-model="fixedForm.educationBackground" size="small" :style="fixStyle" placeholder="请选择患者文化程度">
              <el-option v-for="(i, k) in eduDict" :key="k" :label="i.text" :value="i.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="基础疾病">
            <el-radio-group v-model="hasDiseases" @change="doHasDiseasesChange">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="hasDiseases === 1">
            <el-checkbox-group v-model="fixedForm.underlyingDiseases" @change="doOtherDiseasesChange">
              <el-checkbox :label="i.code" v-for="(i, k) in diseaseDict" :key="k">{{i.text}}</el-checkbox>
              <el-input v-if="fixedForm.underlyingDiseases && fixedForm.underlyingDiseases.indexOf(4) !== -1" v-model="fixedForm.otherDiseases" size="small" placeholder="请填写其他基础疾病" :style="fixStyle"></el-input>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </drawer>
  </div>

  <!--  患者信息  -->
<!--  <drawer-->
<!--    :visible.sync="openPatientInfo"-->
<!--    :width="720"-->
<!--    title="患者信息"-->
<!--    cancel-str="关闭"-->
<!--    confirm-str="编辑"-->
<!--    @confirm="openPatientEditDrawer"-->
<!--    @cancel="closePatientInfoDrawer">-->
<!--    <div class="patient"></div>-->

<!--  </drawer>-->
</template>

<script>
import Drawer from '@/components/Drawer/Drawer'
import { getBaselineInfo, getDictionary, editBaselineInfo } from '@/service/module/followup'
import { genderEnums } from '@/utils/enums'
import regions from '@/utils/region'
import _ from 'lodash'
export default {
  components: {
    Drawer
  },
  props: {
    patientId: [Number, String]
  },
  data () {
    return {
      openPatientInfo: false,
      openPatientEdit: false,
      fixStyle: {
        width: '213px'
      },
      diseaseDict: [],
      eduDict: [],
      brandDict: [],
      diseaseStr: '',
      genderStr: '',
      eduStr: '',
      viewData: {},
      genderEnums,
      regions,
      fixedForm: {},
      formRules: {
        name: [
          { required: true, message: '请输入患者姓名', trigger: 'blur' }
        ],
        age: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ]
      },
      hasDiseases: null,
      addressLabel: [],

      editPatientArchiveHandle: false
    }
  },
  async mounted () {
    await this.getDict()
    const authList = JSON.parse(sessionStorage.getItem('auth')).perms
    authList.find(i => {
      if (i === 'patient-mgr:edit-patient-archive') {
        this.editPatientArchiveHandle = true
      }
    })
  },
  methods: {
    getDict () {
      Promise.all([
        getDictionary('underlyingDisease'),
        getDictionary('eduBackground'),
        getDictionary('ciedBrand')
      ]).then(res => {
        console.log('结束：', res)
        this.diseaseDict = res[0].data
        this.eduDict = res[1].data
        this.brandDict = res[2].data
      }).then(() => {
        this.getData()
      })
      // const resDictionary = await getDictionary('underlyingDisease')
    },
    async getData () {
      try {
        const infoRes = await getBaselineInfo(this.patientId)
        if (infoRes && infoRes.code === 200) {
          this.viewData = infoRes.data
          // 疾病列表code 转换为 文字列表
          const underlyingDiseasesFormat = []
          let underlyingDiseasesStr = ''
          if (this.viewData.underlyingDiseases.length > 0) {
            this.hasDiseases = 1
          } else {
            this.hasDiseases = 0
          }
          for (let i = 0; i < this.viewData.underlyingDiseases.length; i++) {
            underlyingDiseasesStr = (this.diseaseDict.find(o => o.code === this.viewData.underlyingDiseases[i]).text)
            if (this.viewData.underlyingDiseases[i] === 4) { // 其他疾病存在，则合并
              if (this.viewData.otherDiseases) {
                underlyingDiseasesStr += `(${this.viewData.otherDiseases})`
              }
            }
            underlyingDiseasesFormat.push(underlyingDiseasesStr)
          }
          // 疾病列表转换为字符串
          this.diseaseStr = underlyingDiseasesFormat.join('、')
          // 性别 code -> string
          this.genderStr = genderEnums.find(o => o.code === infoRes.data.gender).text
          // 文化程度 code -> string     educationBackground === 0 则跳过 默认为空
          if (infoRes.data.educationBackground) {
            this.eduStr = this.eduDict.find(o => o.code === infoRes.data.educationBackground).text
          }
          if (infoRes.data.educationBackground === 0) {
            this.viewData.educationBackground = ''
          }
          this.fixedForm = _.cloneDeep(this.viewData)
          if (this.fixedForm.district) {
            this.addressLabel = [this.fixedForm.province, this.fixedForm.city, this.fixedForm.district]
          }
        } else {
          this.$message.error('网络错误')
        }
      } catch (e) {
        this.$message.error('网络错误')
        console.log(e)
      }
    },
    openPatientInfoDrawer () {
      this.getData()
      this.openPatientInfo = true
    },
    closePatientInfoDrawer () {
      this.openPatientInfo = false
    },
    openPatientEditDrawer () {
      this.openPatientEdit = true
    },
    closePatientEditDrawer () {
      this.openPatientEdit = false
      this.getData()
    },
    doHasDiseasesChange (val) {
      console.log('hasDiseases:', val)
      if (val === 0) {
        this.fixedForm.underlyingDiseases = []
        this.fixedForm.otherDiseases = ''
      }
    },
    doOtherDiseasesChange (val) {
      console.log('基础疾病筛选：', val.indexOf(4) !== -1)
      if (val.indexOf(4) === -1) {
        this.fixedForm.otherDiseases = ''
      }
    },
    async savePatientInfo () {
      let validRes
      this.$refs.formRules.validate((valid) => {
        if (valid) {
          validRes = true
        } else {
          validRes = false
          return false
        }
      })
      if (!validRes) {
        return false
      }

      if (this.addressLabel.length > 0) {
        this.fixedForm.province = this.addressLabel[0]
        this.fixedForm.city = this.addressLabel[1]
        this.fixedForm.district = this.addressLabel[2]
      }
      this.fixedForm.patientId = this.fixedForm.id
      console.log('保存数据:', this.fixedForm)
      const [err, res] = await this.$to(editBaselineInfo(this.fixedForm))
      if (res && res.code === 200) {
        console.log('保存成功:', res)
        this.$message.success('保存成功')
        this.closePatientEditDrawer()
        this.$emit('watchPatient')
      } else {
        this.$message.error('患者信息保存失败')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../style/common";
  .patient {
    position: relative;
    height: calc(100vh - 194.5px);
    .view {
      padding: 16px;
    }
    .btn-wrap {
      text-align: right;
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 64px;
      border-top: 1px solid #e7e8ea;
      display: flex;
      align-items: center;
      justify-content: right;
      padding-right: 24px;
    }
  }
  .edit {
    ::v-deep .el-checkbox {
      margin-right: 12px;
    }
  }
</style>
