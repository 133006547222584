<template>
  <div class="proofread">
    <div class="header">
      <div class="header-info">
        <div v-if="planItem.type === 1" class="info-title">不适随访</div>
        <div v-if="planItem.type === 0" class="info-title">
          术后第<span v-if="planItem.year && planItem.year !== 0">{{planItem.year}}年</span>
          <span v-if="planItem.month && planItem.month !== 0">{{planItem.month}}个月</span>随访
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="info-subtitle">程控信息</div>
      </div>
      <div>
        <el-button class="header-btn" type="normal" @click="goBack">返回</el-button>
      </div>
    </div>
    <div class="proofread-body">
      <div class="pdf-data">
        <div class="form-comp__header">
          <div class="header-icon"></div>
          <div class="header-title">程控信息</div>
        </div>
        <div class="form-comp">
          <el-scrollbar style="height: 100%">
            <CompleteForm v-if="show" :form-data="pdfData" :report-id="reportId"></CompleteForm>
          </el-scrollbar>
        </div>
        <div class="empty-bottom" style="height: 16px; flex-shrink: 0"></div>
      </div>
      <div class="pdf-view">
        <div class="form-comp__header">
          <div class="header-icon"></div>
          <div class="header-title">程控报告.pdf</div>
        </div>
        <div class="pdf-view__content">
          <iframe class="pdf-frame" :src="pdfUrl" frameborder="0"></iframe>
        </div>
        <div class="empty-bottom" style="height: 16px; flex-shrink: 0"></div>
      </div>
    </div>
  </div>
</template>

<script>
import CompleteForm from './components/CompleteForm'
import { getReportDetail } from '@/service/module/verify'
const moment = require('moment')
export default {
  components: {
    CompleteForm
  },
  data () {
    return {
      moment,
      reportId: null,
      pdfData: [],
      show: false,
      numPages: undefined,
      loadingTask: null,
      timeout: null,
      showPdf: false,
      targetLength: 0,
      proofreadLength: 0,
      pdfUrl: '',
      planItem: {}
    }
  },
  mounted () {
    const { reportId } = this.$route.query
    const { info, pdfUrl } = this.$route.params

    this.pdfUrl = pdfUrl
    this.planItem = info || {}
    console.log(this.planItem)
    console.log('this.pdfUrl：', this.pdfUrl)
    if (reportId) {
      this.reportId = reportId
      this.getData()
      this.show = true
    }
  },
  methods: {
    async getData () {
      const [err, res] = await this.$to(getReportDetail({
        recordId: this.reportId,
        audit: false
      }))
      if (res && res.code === 200) {
        this.pdfData = res.data.data.nodes
        this.reportId = res.data.id
        this.deepEach(this.pdfData)
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    deepEach (nodes) {
      if (!nodes.length) {
        return
      }
      const arr = []
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i]
        if (node.type && node.type === 'data') {
          this.$set(node, 'disabled', true)
          arr.push(node)
        }
        if (node.nodes && node.nodes.length) {
          arr.push(...this.deepEach(node.nodes))
        }
      }
      return arr
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../style/common";
  .proofread {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
    position:absolute;
    top:0;
    left:0;
    min-width: 1100px;padding: 0 17px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      &-info {
        display: flex;
        align-items: center;
        .info-title {
          @include fs(#131414, 20px, 700);
        }
        .info-subtitle {
          @include fs(#606266, 16px, 400);
        }
        ::v-deep .el-divider--vertical {
          margin: 0 12px;
        }
      }
      &-btn {
        width: 68px;
      }
    }
    .proofread-body {
      flex: 1;
      display: flex;
      overflow: hidden;
      .pdf-data, .pdf-view {
        /*flex: 1;*/
        .form-comp {
          &__header {
            height: 44px;
            border: 1px solid #E4E7ED;
            border-bottom: none;
            border-radius: 4px 4px 0 0;
            display: flex;
            align-items: center;
            padding-left: 16px;
            .header-icon {
              @include domSize(24px, 24px);
              background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-header.png") no-repeat;
              background-size: 100% 100%;
              margin-right: 6px;
            }
            .header-title {
              @include fs(#0F1114, 16px, 500);
            }
          }
          .pdf-wrap {

          }
        }
      }
      .pdf-data {
        width: 508px;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        .form-comp {
          flex: 1;
          overflow: hidden;
          border: 1px solid #E4E7ED;
        }
      }
      .pdf-view {
        flex: 1;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        .form-comp {
          &__header {
            flex-shrink: 0;
            border: 1px solid #E4E7ED;
            .header-icon {
              background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-header_pdf.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        .pdf-view__content {
          flex: 1;
          overflow: hidden;
          border: 1px solid #E4E7ED;
          border-top: none;
          .pdf-frame {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
}
</style>
