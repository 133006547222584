<template>
  <div class="test-wrap">
    <div class="page-header">
      <div class="patient-info">
        <div class="patient-name">{{userData.name}}</div>
        <div class="patient-gender cut-line">性别: <span style="color: #131414">{{userData.genderFormat}}</span></div>
        <div class="patient-age cut-line">年龄: <span style="color: #131414">{{userData.age}}岁</span></div>
        <div class="patient-age cut-line">病历号: <span style="color: #131414">{{userData.recordNo}}</span></div>
        <div class="patient-disease">
          基础疾病:
          <span style="color: #131414">{{diseaseStr ? diseaseStr : '-'}}</span>
        </div>
<!--        <div class="check-more" @click="handlePatient">查看更多</div>-->
      </div>
      <div v-if="uploadReportHandle" class="info-edit">
        <el-button type="primary" @click="handelCreateFollowup">上传程控随访PDF</el-button>
      </div>
    </div>
    <ul class="menu-tab">
      <li @click="checkTab(k)"
          v-for="(i, k) in tabList"
          :class="tabActive === k
          ? 'active-tab' : ''"
          :key="k"
          class="tab-item"
          :style="k > 4 ? { cursor: 'not-allowed' } : {}">{{i}}</li>
    </ul>
    <div v-show="tabActive === 0" class="detail-box">
      <div v-if="havePlan" class="plan-info">
        <div class="plan-total">
          <div class="total-num">{{planList.length}}</div>
          <div class="total-label">当前随访数</div>
        </div>
        <div class="divider"></div>
        <div class="plan-list" ref="scroll">
          <div class="scroll-content">
            <div v-for="(i, k) in planList" :key="k" class="plan-item" :style="k > 2 ? { cursor: 'not-allowed' } : {}" @click="clickPlan(k)" :class="active === k ? 'active-plan': ''">
              <div class="item">
                <div v-if="i.type === 0" class="item-times">
                  <span class="large-text" style="margin-right: 2px">
                    术后
                  </span>
                  <span v-if="i.year !== 0" class="large-text number-text">
                    {{i.year}}
                  </span>
                  <span v-if="i.year !== 0" class="little-text">年</span>
                  <span v-if="i.month !== 0"  class="large-text number-text">
                    {{i.month}}
                  </span>
                  <span v-if="i.month !== 0" class="little-text">
                    个月
                  </span>
                  <span class="little-text">
                    随访
                  </span>
                </div>
                <div v-if="i.type === 1" class="item-times">
                  <span class="large-text" style="margin-right: 2px">
                    不适
                  </span>
                  <span class="little-text">
                    随访
                  </span>
                </div>
                <div v-if="i.type === 2" class="item-times">
                  <span class="large-text" style="margin-right: 2px">
                    自建
                  </span>
                  <span class="little-text">
                    随访
                  </span>
                </div>
                <div class="item-date">{{i.visitTime ? moment.unix(i.visitTime / 1000).format('Y-MM-DD') : '-'}}</div>
                <div class="arrow"></div>
              </div>
            </div>
          </div>
          <img target="_blank" v-if="isShowLeftIcon" class="control-btn control-pre" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/huadong-left.png" @click="onChangeLeft"/>
          <img target="_blank" v-if="isShowRightIcon" class="control-btn control-next" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/huadong-right.png" @click="onChangeRight"/>
        </div>
      </div>
      <div v-if="havePlan" class="info-detail">
        <div class="pdf-data">
          <el-scrollbar style="height: 100%">
            <div class="pdf-data__content">
              <div class="implant-wrap common-wrap">
                <div class="implant-header common-header">
                  <div class="brand-info">
                    <img v-if="ciedBrandType === '1'" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/logo-Medtonic.png" alt="">
                    <img v-if="ciedBrandType === '2'" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/logo-Abbott.png" alt="">
                    <img v-if="ciedBrandType === '3'" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/logo-Biotronik.png" alt="">
                    <img v-if="ciedBrandType === '4'" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/logo-Boston.png" alt="">
                    <span v-if="ciedBrandType === '5'">{{implantTemp.otherCiedBrand}}</span>
                    <div class="brand-model">{{ciedModel}}</div>
                    <div style="margin-left: 16px" class="brand-model">{{implantTemp.ciedSn}}</div>
                  </div>
                  <div class="check-more" v-if="viewMoreHandle" @click="handleImplant">查看更多</div>
                </div>
                <div class="implant-body common-body">
                  <el-descriptions title="" :column="2">
                    <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="植入日期">
                      {{moment.unix(implantTemp.implantDate / 1000).format('Y-MM-DD')}}
                    </el-descriptions-item>
                    <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="电池">
                      {{deviceData.batteryRemaining}}/{{deviceData.batteryVoltage}}
                    </el-descriptions-item>
                    <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="植入部位">
                      {{implantTemp.ciedImplantSite > -1 ? implantSizeDict.find(o => o.code === implantTemp.ciedImplantSite).text : '-'}}
                      <span v-if="implantTemp.ciedImplantSite === 3 && implantTemp.otherImplantSite">({{implantTemp.otherImplantSite}})</span>
                    </el-descriptions-item>
                    <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="CIED类型">
                      {{implantTemp.ciedKindLabel ? implantTemp.ciedKindLabel: '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="适应证">
                      {{implantTemp.adaptationDiseaseLabel ? implantTemp.adaptationDiseaseLabel : '-'}}
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
              </div>
              <div class="control-wrap common-wrap">
                <div class="control-header common-header">
                  <div class="control-title flex-center">
                    <div class="title-content">
                      <div class="icon"></div>
                      <div class="text">程控信息</div>
                    </div>
                    <div v-if="pdfData.status === 1 || pdfData.status === 2" class="verify-date"></div>
                    <div v-if="pdfData.status === 1 || pdfData.status === 2" class="verify-status flex-center">
                      <i class="verify-status__icon"></i>
                      <span class="verify-status__text">尚未完成人工校对</span>
                    </div>
                  </div>
                  <div class="check-more" v-if="haveControl && viewMoreHandle" @click="handleComplete">查看更多</div>
                  <div class="check-more" v-if="(pdfData.status === 1 || pdfData.status === 2) && viewMoreHandle" @click="handleCompleteUnchecked">查看更多</div>
                </div>
                <div v-if="showDevice" class="control-body">
                  <div class="device-info">
                    <div class="device-title device-common_title">器械状态：</div>
                    <div class="device-container">
                      <div class="device-header">
                        <div style="width: 16%" class="device-params"></div>
                        <div style="width: 28%" class="device-params">夺获阈值</div>
                        <div style="width: 28%" class="device-params">感知</div>
                        <div style="width: 28%" class="device-params">电极导线阻抗</div>
                      </div>
                      <div class="device-content">
                        <div v-for="(i, k) in deviceData.deviceStatus" :key="k" class="device-item">
                          <div style="width: 16%; color: #909399" class="device-column">{{i.name}}</div>
                          <div style="width: 28%" class="device-column">
                            {{(!i.captureThreshold || i.captureThreshold === ' ') ? '-' : i.captureThreshold}}
                          </div>
                          <div style="width: 28%" class="device-column">
                            {{(!i.programmedSensitivity || i.programmedSensitivity === ' ') ? '-' : i.programmedSensitivity}}
                          </div>
                          <div style="width: 28%" class="device-column">
                            {{(!i.leadImpedance || i.leadImpedance === ' ') ? '-' : i.leadImpedance}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="device-model">
                      <div class="model-item">
                        <div class="item-label">模式</div>
                        <div class="item-val">{{deviceData.mode ? deviceData.mode : '-'}}</div>
                      </div>
                      <div class="model-item">
                        <div class="item-label">低限频率</div>
                        <div class="item-val">{{deviceData.lowerRate ? deviceData.lowerRate : '-'}}</div>
                      </div>
                    </div>
                    <div class="device-rate">
                      <div class="rate-title device-common_title">心房心室起搏百分比：</div>
                      <div class="rate-container">
                        <div class="rate-line">
                          <div class="rate-item">
                            <div class="item-label">AS-VS</div>
                            <div class="item-val">{{deviceData.pacing ? deviceData.pacing.asVs : '-'}}</div>
                          </div>
                          <div class="rate-item">
                            <div class="item-label">AS-VP</div>
                            <div class="item-val">{{deviceData.pacing ? deviceData.pacing.asVp : '-'}}</div>
                          </div>
                        </div>
                        <div class="rate-line">
                          <div class="rate-item">
                            <div class="item-label">AP-VS</div>
                            <div class="item-val">{{deviceData.pacing ? deviceData.pacing.apVs : '-'}}</div>
                          </div>
                          <div class="rate-item">
                            <div class="item-label">AP-VP</div>
                            <div class="item-val">{{deviceData.pacing ? deviceData.pacing.apVp : '-'}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="device-alert">
                      <div class="device-common_title">预警事件：</div>
                      <div class="alert-container">
                        {{deviceData.observation ? deviceData.observation : '当前程控随访期间没有预警事件'}}
                      </div>
                    </div>
                    <div class="device-log">
                      <div class="device-common_title">当前程控随访所做修改：</div>
                      <div class="log-container">
                        <div v-if="controlChange.length === 0">当前程控随访期间没有参数被修改</div>
                        <div v-else>
                          <div class="change-box" v-for="(i, k) in controlChange" :key="k">
                            <div class="change-item item-title">{{i.name}}: </div>
                            <div class="change-item">{{i.oldValue}}</div>
                            <div class="change-item item-icon"> -></div>
                            <div class="change-item">{{i.newValue}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Empty v-else text="暂无信息" height="105px"></Empty>
              </div>
              <div class="files-wrap common-wrap">
                <div class="files-header common-header">
                  <div class="files-title flex-center">
                    <div class="icon"></div>
                    <div class="text">随访信息</div>
                  </div>
                  <el-button v-if="pdfData.status === 3 && editFollowupHandle" class="edit-btn" size="mini" plain @click="editData">编辑<i class="el-icon-edit el-icon--right"></i></el-button>
                </div>
                <div v-if="recordDate" class="follow-up-info">
                  <div class="info-title">程控报告日期：</div>
                  <div class="info-container">
                    {{recordDate}}
                  </div>
                </div>
                <div v-if="malAdaptation" class="follow-up-info">
                  <div class="info-title">不适症状：</div>
                  <div class="info-container">
                    {{malAdaptation}}
                  </div>
                </div>
                <div class="files-body">
                  <div v-if="filesList.length > 0" class="files">
                    <a :href="i.url" target="_blank" class="files-item" v-for="(i, k) in filesList" :key="k">
                      <div class="item-info">
                        <div class="item-info__icon">
                          <img v-if="i.fileFormat === 'pdf'" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-pdf.png" alt="">
                          <img v-if="['doc', 'docx'].indexOf(i.fileFormat) !== -1" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-word.png" alt="">
                          <img v-if="['xls', 'xlsx'].indexOf(i.fileFormat) !== -1" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-excel.png" alt="">
                          <img v-if="['png', 'jpg', 'jpeg'].indexOf(i.fileFormat) !== -1" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-picture.png" alt="">
                        </div>
                        <div class="item-info__desc">
                          <div class="desc-title">{{i.filename}}.{{i.fileFormat}}</div>
                          <div class="desc-size">{{i.sizeFormat}}</div>
                        </div>
                      </div>
                      <div class="item-preview"></div>
                    </a>
                  </div>
                  <div v-else class="empty">暂无信息</div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="pdf-view">
          <div class="form-comp__header">
            <div class="header-icon"></div>
            <div class="header-title">程控报告.pdf</div>
          </div>
          <div class="pdf-view__content">
            <div class="pdf-view__body">
              <Empty v-if="!pdfData.url" text="暂无报告" :show-img="true"></Empty>
              <iframe v-else class="pdf-frame" :src="pdfUrl" frameborder="0"></iframe>
            </div>
          </div>
          <div class="empty-bottom" style="height: 16px; flex-shrink: 0"></div>
        </div>
      </div>
      <Empty v-else text="暂无随访" :show-img="true"></Empty>
    </div>
    <MorePlan v-if="tabActive === 1" :patient-id="patientId" ref="morePlan"></MorePlan>
    <Patient v-if="tabActive === 2" :patient-id="patientId" @watchPatient="watchPatient" ref="patient"></Patient>
    <Psychological v-if="tabActive === 3" :patient-id="patientId" ref="psychological"></Psychological>
    <Compare v-if="tabActive === 4" :patient-id="patientId"></Compare>
    <Files ref="files"></Files>
    <Implant ref="implant"></Implant>
  </div>
</template>
<script>
import BScroll from '@better-scroll/core'
import Psychological from './components/Psychological'
import Files from './components/Files'
import Patient from './components/Patient'
import Implant from './components/Implant'
import Compare from './components/Compare'
import Empty from '@/components/Empty'
import MorePlan from './components/morePlan'
import _ from 'lodash'
import eventBus from '@/libs/utils/eventBus'
import patient from './patient-mixin'
import { diffDateStamp } from '@/libs/utils'
import {
  getBaselineInfo,
  getDictionary,
  getFileList,
  getImplant,
  getFollowUpRecords,
  getCiedReport,
  getReportOverwrite,
  hasFollowUp,
  invalidFollowUpRecord
} from '@/service/module/followup'
import {
  arrhythmiaRadioDict,
  bradycardiaRadioDict,
  ciedTypeDict,
  genderEnums,
  implantSizeDict, indicationsDict
} from '@/utils/enums'

const moment = require('moment')
export default {
  mixins: [patient],
  components: {
    Files,
    Empty,
    Compare,
    Patient,
    Implant,
    MorePlan,
    Psychological
  },
  data () {
    return {
      endPointX: 0,
      patientId: '',
      reportId: null,
      activeRecordId: null,
      userData: {},
      planList: [],
      filesList: [],
      pdfData: {},
      implantSizeDict,
      brandDict: [],
      medtronicDict: [],
      diseaseDict: [],
      implantDate: null,
      moment: moment,
      diseaseStr: '',
      implantTemp: {},
      pdfUrl: '',
      deviceData: {},
      controlChange: [],
      ciedBrandType: null,
      showDevice: false,
      malAdaptation: '', // 不适症状
      recordDate: null,
      describe: '',
      isShowRightIcon: true,
      isShowLeftIcon: false,

      uploadReportHandle: false,
      viewMoreHandle: false,
      editFollowupHandle: false

    }
  },
  created () {
    const { patientId } = this.$route.query
    if (patientId) {
      this.patientId = patientId
    }
    this._send('$pageView', '患者档案进入情况', '患者档案页面 加载成功时触发', {
      accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
      doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
      hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
    })
    const authList = JSON.parse(sessionStorage.getItem('auth')).perms
    authList.find(i => {
      if (i === 'patient-mgr:upload-report') {
        this.uploadReportHandle = true
      }
      if (i === 'patient-mgr:view-more') {
        this.viewMoreHandle = true
      }
      if (i === 'patient-mgr:edit-followup') {
        this.editFollowupHandle = true
      }
    })
  },
  async mounted () {
    await this.getData()
    await this.getImplant()
    await this.gePlanList()
    eventBus.$on('saveImplant', async () => {
      await this.getImplant()
      await this.gePlanList()
    })
  },
  methods: {
    init () {
      if (!this.bs) {
        this.bs = new BScroll(this.$refs.scroll, {
          scrollX: true,
          probeType: 1
        })
        this.bs.on('scrollStart', () => {
          console.log('scrollStart-')
        })
        this.bs.on('scroll', ({ y }) => {
          console.log('scrolling-')
        })
        this.bs.on('scrollEnd', () => {
          console.log('scrollingEnd')
        })
      }
    },
    async gePlanList () {
      const [planErr, planRes] = await this.$to(getFollowUpRecords({
        patientId: this.patientId
      }))
      if (planRes && planRes.code === 200) {
        this.planList = planRes.data.reverse() || []

        if (this.planList.length > 0) {
          for (let i = 0; i < this.planList.length; i++) {
            this.planList[i].year = diffDateStamp(this.implantDate, this.planList[i].visitTime)[0]
            this.planList[i].month = diffDateStamp(this.implantDate, this.planList[i].visitTime)[1]
          }
          this.active = 0
          this.activeRecordId = this.planList[0].id
          this.malAdaptation = this.planList[0].malAdaptation

          var data = this.planList[0]
          switch (data.type) {
            case 0 :
              this.describe = '术后'
              if (data.year !== 0) {
                this.describe += data.year + '年'
              }
              if (data.month !== 0) {
                this.describe += data.month + '个月'
              }
              this.describe += '随访'
              break
            case 1 :this.describe = '不适随访'
              break
            case 2 : this.describe = '自建随访'
              break
          }
          this.recordDate = this.describe + ' ' + moment.unix(this.planList[0].visitTime / 1000).format('Y-MM-DD')
          this.controlChange = []
          this.deviceData = {}
        }
        if (this.planList.length > 2) {
          this.init()
          this.$nextTick(() => {
            this.bs.refresh()
            const dom = document.querySelector('.active-plan')
            this.bs.scrollToElement(dom, 0)
          })
        }
      } else {
        this.$message.error('获取随访计划失败')
      }
      if (planErr) {
        this.$message.error('网络错误')
        throw new Error(planErr)
      }
      if (this.planList.length > 0) {
        await this.getReport()
        if (this.reportId) {
          await this.getDeviceStatus()
        } else {
          this.showDevice = false
        }
        await this.getFiles()
      }
    },
    async getData () {
      try {
        const dictRes = await getDictionary('underlyingDisease')
        const infoRes = await getBaselineInfo(this.patientId)
        if (dictRes && dictRes.code === 200) {
          this.diseaseDict = dictRes.data
          this.userData = infoRes.data
          // 疾病列表code 转换为 文字列表
          const underlyingDiseasesFormat = []
          let underlyingDiseasesStr = ''

          for (let i = 0; i < this.userData.underlyingDiseases.length; i++) {
            underlyingDiseasesStr = (this.diseaseDict.find(o => o.code === this.userData.underlyingDiseases[i]).text)
            if (this.userData.underlyingDiseases[i] === 4) { // 其他疾病存在，则合并
              if (this.userData.otherDiseases) {
                underlyingDiseasesStr += `(${this.userData.otherDiseases})`
              }
            }
            underlyingDiseasesFormat.push(underlyingDiseasesStr)
          }
          // 疾病列表转换为字符串
          this.diseaseStr = underlyingDiseasesFormat.join('、')
          this.userData.genderFormat = genderEnums.find(o => o.code === infoRes.data.gender).text
          console.log('用户:', this.userData)
        } else {
          this.$message.error('网络错误')
        }
      } catch (e) {
        this.$message.error('网络错误')
        console.log(e)
      }
    },
    async getImplant () {
      const brandRes = await getDictionary('ciedBrand')
      if (brandRes && brandRes.code === 200) {
        this.brandDict = brandRes.data
        this.medtronicDict = brandRes.data.find(o => o.code === '1').children
      }
      const [err, res] = await this.$to(getImplant(this.patientId))
      if (res && res.code === 200) {
        console.log('植入信息:', res.data)
        this.implantTemp = res.data
        this.implantDate = res.data.implantDate
        // 预览数据处理
        // 起搏器类型处理
        if (this.implantTemp.ciedKind.ciedTypeRadio >= 0) {
          if (this.implantTemp.ciedKind.otherCiedType) {
            this.implantTemp.ciedKindLabel = this.implantTemp.ciedKind.otherCiedType
          } else {
            this.implantTemp.ciedKindLabel = ciedTypeDict.find(o => o.code === this.implantTemp.ciedKind.ciedTypeRadio).text
          }
        }
        // 处理型号
        if (this.implantTemp.ciedBrand.key !== null) {
          this.ciedBrandType = this.implantTemp.ciedBrand
          // const brandStr = this.brandDict.find(o => o.code === (this.implantTemp.ciedBrand.key + 1) + '').title || '-'

          /**
           *  如果 model ！== '0' && ！== ''   有型号， 则 通过brandCode 查询对应module 再通过modelCode 反查name
           *  如果 model === '0' 直接 赋值 otherModel
           *  如果 model === '' 直接 ''
           * */
          let detailStr = ''
          if (!this.implantTemp.ciedModel) {
            detailStr = ''
          } else if (this.implantTemp.ciedModel === '0') {
            detailStr = this.implantTemp.otherCiedModel
          } else {
            if (this.implantTemp.ciedBrand && this.implantTemp.ciedBrand !== '5') {
              const modelList = this.brandDict.find(o => o.code === this.implantTemp.ciedBrand).models
              if (modelList.find(i => i.code === this.implantTemp.ciedModel)) {
                detailStr = modelList.find(i => i.code === this.implantTemp.ciedModel).name
              }
            }
            if (this.implantTemp.ciedBrand === '5') {
              detailStr = this.implantTemp.otherCiedModel
            }
            if (!this.implantTemp.ciedModel) {
              detailStr = ''
            }
          }

          this.ciedModel = detailStr
        }

        // 适应证处理
        // 适应证
        let fitsChild = ''
        let secondChild = ''
        const adaptationDiseaseStrList = []
        const adaptationDisease = _.cloneDeep(this.implantTemp.adaptationDisease)
        // 处理心动过缓下级选项
        if (this.implantTemp.adaptationDisease.bradycardiaRadio !== null) {
          fitsChild = bradycardiaRadioDict.find(o => o.code === adaptationDisease.bradycardiaRadio).text
        }
        // 处理心律失常下级选项
        if (this.implantTemp.adaptationDisease.arrhythmiaRadio && this.implantTemp.adaptationDisease.arrhythmiaRadio !== null) {
          secondChild = arrhythmiaRadioDict.find(o => o.code === adaptationDisease.arrhythmiaRadio).text
        }
        for (let i = 0; i < adaptationDisease.indicationsSymptom.length; i++) {
          let tempStr = indicationsDict.find(o => o.code === adaptationDisease.indicationsSymptom[i]).text
          if (adaptationDisease.indicationsSymptom[i] === 0) { // 选中心率过缓
            if (fitsChild) {
              tempStr = `${tempStr}(${fitsChild})`
            }
          } else if (adaptationDisease.indicationsSymptom[i] === 2) { // 选中心率时失常
            if (secondChild) {
              tempStr = `${tempStr}(${secondChild})`
            }
          } else if (adaptationDisease.indicationsSymptom[i] === 3) { // 选中其他
            if (adaptationDisease.otherIndications) {
              tempStr = `${tempStr}(${adaptationDisease.otherIndications})`
            }
          }
          adaptationDiseaseStrList.push(tempStr)
        }
        this.implantTemp.adaptationDiseaseLabel = adaptationDiseaseStrList.join('、')

        console.log('implantTemp:', this.implantTemp)
      } else {
        this.$message.error('植入信息获取失败')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    async getFiles () {
      const [err, res] = await this.$to(getFileList(this.activeRecordId))
      if (res && res.code === 200) {
        this.filesList = res.data || []
      } else {
        this.$message.error('网络错误')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    async getReport () {
      const [err, res] = await this.$to(getCiedReport(this.activeRecordId))
      if (res && res.code === 200) {
        this.pdfData = res.data || {}
        if (this.pdfData.status === 3) {
          this.haveControl = true
        } else {
          this.haveControl = false
        }
        const url = this.pdfData.url

        if (url) {
          const newUrlArr = url.split(':')
          if (newUrlArr[0] === 'http') {
            newUrlArr[0] = 'https'
          }
          const newUrl = newUrlArr.join(':')
          this.pdfUrl = newUrl
        } else {
          this.pdfUrl = this.pdfData.url
        }
        this.reportId = this.pdfData.id
      } else {
        this.$message.error('获取程控报告失败')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    async getDeviceStatus () {
      const [err, res] = await this.$to(getReportOverwrite(this.reportId))
      if (res && res.code === 200) {
        this.deviceData = res.data.params || {}

        const contrastParams = res.data.contrastParams || []
        contrastParams.forEach(item => {
          if (item.oldValue !== item.newValue) {
            this.controlChange.push(item)
          }
        })
        if (!res.data.params) {
          this.showDevice = false
        } else {
          this.showDevice = true
        }
        console.log(this.deviceData)
      } else {
        this.$message.error('获取设备状态失败')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    checkTab (index) {
      if (index === 4) {
        this._send('$click', '随访数据对比表点击', '随访数据对比表按钮 发生点击行为触发', {
          accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
          doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
          hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
        })
      }
      if (index > 4) {
        return false
      }
      this.tabActive = index
    },
    async clickPlan (index) {
      this.active = index
      this.activeRecordId = this.planList[index].id
      this.malAdaptation = this.planList[index].malAdaptation

      var data = this.planList[index]
      switch (data.type) {
        case 0 :
          this.describe = '术后'
          if (data.year !== 0) {
            this.describe += data.year + '年'
          }
          if (data.month !== 0) {
            this.describe += data.month + '个月'
          }
          this.describe += '随访'
          break
        case 1 :this.describe = '不适随访'
          break
        case 2 : this.describe = '自建随访'
          break
      }
      this.recordDate = this.describe + ' ' + moment.unix(this.planList[index].visitTime / 1000).format('Y-MM-DD')
      this.controlChange = []
      this.deviceData = {}
      this.$nextTick(() => {
        this.bs.refresh()
      })
      await this.getReport()
      if (this.reportId) {
        await this.getDeviceStatus()
      } else {
        this.showDevice = false
      }
      await this.getFiles()
    },
    handleImplant () {
      this.$refs.implant.openImplantViewDrawer()
      this._send('$click', '植入信息查看更多点击', '植入信息查看更多按钮 发生点击行为触发', {
        accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
        doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
        hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
      })
    },
    handleComplete () {
      this._send('$click', '程控信息查看更多点击', '程控信息查看更多按钮 发生点击行为触发', {
        accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
        doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
        hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
      })
      this.$router.push({
        name: 'completeInfo',
        query: {
          reportId: this.reportId ? this.reportId : ''
        },
        params: {
          info: this.planList[this.active],
          pdfUrl: this.pdfUrl
        }
      })
    },
    handleCompleteUnchecked () {
      this.$router.push({
        path: 'proofread',
        query: {
          reportId: this.reportId,
          from: 'patientFiles',
          patientId: this.patientId
        }
      })
    },
    watchPatient () {
      this.getData()
    },
    async handelCreateFollowup () {
      this._send('$click', '患者档案上传程控随访PDF点击', '患者档案上传程控随访PDF按钮 发生点击行为触发', {
        accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
        doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
        hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
      })
      const [err, res] = await this.$to(hasFollowUp({
        patientId: this.patientId
      }))
      if (res && res.code === 200) {
        console.log('是否进行中:', res, res.data.exist)
        if (!res.data.exist) {
          this.$router.push({
            name: 'createFollowup',
            query: {
              patientId: this.patientId,
              name: this.userData.name,
              medicalRecordNumber: this.userData.recordNo,
              isNewFollowUp: true,
              fromPage: 'patientFiles'
            }
          })
        } else {
          this.$confirm('当前还有未完成的上传任务，新建上传将导致数据丢失', '提示', {
            confirmButtonText: '继续上传',
            cancelButtonText: '新建上传',
            type: 'warning',
            distinguishCancelAndClose: true
          }).then(() => {
            this._send('$click', '患者档案继续上传点击', '患者档案继续上传按钮 发生点击行为触发', {
              accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
              doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
              hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
            })
            this.$router.push({
              name: 'createFollowup',
              query: {
                recordId: res.data.recordId,
                patientId: this.$route.query.patientId,
                name: this.userData.name,
                medicalRecordNumber: this.userData.recordNo,
                isNewFollowUp: false,
                fromPage: 'patientFiles'
              }
            })
          }).catch((action) => {
            if (action === 'close') {
              return
            }
            if (res.data.recordId) {
              this.invalidFollowUpRecord(res.data.recordId)
              this._send('$click', '患者档案新建上传点击', '患者档案新建上传按钮 发生点击行为触发', {
                accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
                doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
                hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
              })
            }
          })
        }
      } else {
        this.$message.error('获取随访状态失败')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    invalidFollowUpRecord (recordId) {
      invalidFollowUpRecord({
        recordId: recordId
      }).then(res => {
        if (res.code === 200) {
          this.createNew()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    createNew () {
      this.$router.push({
        name: 'createFollowup',
        query: {
          patientId: this.patientId,
          name: this.userData.name,
          medicalRecordNumber: this.userData.recordNo,
          isNewFollowUp: true,
          fromPage: 'patientFiles'
        }

      })
    },
    editData () {
      this._send('$click', '随访信息编辑点击', '随访信息编辑按钮 发生点击行为触发', {
        accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
        doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
        hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
      })
      this.$router.push({
        name: 'editFollowup',
        query: {
          recordId: this.activeRecordId,
          patientId: this.patientId,
          name: this.userData.name,
          medicalRecordNumber: this.userData.recordNo
        }
      })
    },
    handleMorePlan () {
      this.$refs.morePlan.openPlanViewDrawer()
    },
    onChangeRight () {
      const hooks = this.bs.scroller.hooks
      hooks.on('scrollTo', (endPoint) => {
        this.endPointX = endPoint.x
      })
      this.bs.scrollTo(-200 + this.endPointX, 0, 300)
      if (this.bs.maxScrollX >= this.bs.x) {
        console.log('滑到最右侧了')
        this.isShowRightIcon = false
        this.isShowLeftIcon = true
      } else {
        this.isShowRightIcon = true
        this.isShowLeftIcon = true
      }
    },
    onChangeLeft () {
      this.bs.scrollTo(200 + this.endPointX, 0, 300)
      if (this.bs.x >= 0) {
        console.log('滑到最左侧了')
        this.isShowLeftIcon = false
        this.isShowRightIcon = true
      } else {
        this.isShowRightIcon = true
        this.isShowLeftIcon = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../style/common";
  .test-wrap {
    min-width: 1100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    /*height: 100%;*/
    /*overflow: auto;*/
    /*position:absolute;*/
    /*top:0;*/
    /*left:0;*/
  .page-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 24px 16px;
    border-bottom: 1px solid #dcdfe6;
    .patient-info {
      display: flex;
      align-items: center;
      @include fs (#909399, 14px, 400);
      .patient-name {
        @include fs (#131414, 20px, 700);
        margin-right: 24px;
      }
      .cut-line {
        &:after {
          content: ' ';
          display: inline-block;
          background-color: #E7E8EA;
          margin: 0 12px;
          @include domSize(1px, 8px);
        }
      }
    }
    .info-edit {
      display: flex;
      align-items: center;
      @include fs (#606266, 13px, 400);
      .psychological-scale, .other-files {
        display: flex;
        align-items: center;
        cursor: pointer;
        @include domSize(108px, 38px);
      }
      .center-line {
        margin: 0 24px;
        background-color: #E7E8EA;
        @include domSize(1px, 16px);
      }
      .icon {
        @include domSize (38px, 38px);
        img {
          display: block;
          @include domSize(100%, 100%);
        }
      }
    }
  }
  .menu-tab {
    margin-left: 24px;
    height: 56px;
    display: flex;
    align-items: center;
    .tab-item {
      padding: 5px 12px;
      cursor: pointer;
      @include fs(#51565D, 14px, 400);
      margin-right: 4px;
      &.active-tab {
        background: #ECF5FF;
        color: #409EFF;
      }
    }
  }
  .detail-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .plan-info {
      display: flex;
      align-items: center;
      height: 84px;
      padding: 0 24px;
      // box-shadow: 0px 0.1px 4px 2px rgba(0, 0, 0, 0.2);
      .plan-total {
        @include domSize(70px, 58px);
        .total-num {
          text-align: center;
          @include fs (#303133, 24px, 500);
        }
        .total-label {
          @include fs (#909399, 14px, 400);
        }
      }
      .divider {
        @include domSize (1px, 36px);
        background-color: #E7E8EA;
        margin: 0 16px;
      }
      .plan-list {
        flex: 1;
        height: 100%;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        .scroll-content {
          display: inline-block;
          height: 76px;
          background: #F5F7FA;
          flex-wrap: nowrap;
          align-items: center;
          min-width: 100%;
        }
        .plan-item {
          width: 133px;
          /*line-height: 99px;*/
          display: inline-block;
          height: 76px;
          align-items: center;
          position: relative;
          .item {
            width: 100%;
            height: 100%;
            padding: 16px 0 0 16px;
            vertical-align: middle;
            display: inline-block;
            cursor: pointer;
            .item-times {
              display: flex;
              align-items: flex-end;
              margin-bottom: 8px;
              span {
                display: inline-block;
                /*vertical-align: bottom;*/
                line-height: 1;
                @include fs(#606266, 20px, 500);
              }
              .large-text {
                position: relative;
                top: 1px;
              }
              .number-text {
                top: 2px;
              }
              .little-text {
                font-size: 12px;
                position: relative;
                bottom: 1px;
              }
            }
          }
          &::after {
            content: ' ';
            display: inline-block;
            height: 20px;
            width: 1px;
            background: #E4E7ED;
            vertical-align: middle;
          }
          &:last-of-type {
            &::after {
              content: ' ';
              display: inline-block;
              height: 0;
              width: 0;
            }
          }
        }
        .plan-item.active-plan {
          .item {
            background: #409EFF;
            border-radius: 4px;
            overflow: hidden;
            .item-times {
              span {
                color: #ffffff;
              }
            }
            .item-date {
              color: #ffffff;
              background-color: #409EFF;
            }
            .arrow {
              position: absolute;
              bottom: -12px;
              left: 50%;
              transform: translateX(-50%);
              border: 6px solid #409EFF;
              border-bottom-color: transparent;
              border-left-color: transparent;
              border-right-color: transparent;
              margin: 0 auto;
              @include domSize(0, 0);
            }
          }
        }
        .control-btn {
          position: absolute;
          z-index: 999;
          top: 18px;
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
        .control-pre {
          left: 20px;
        }
        .control-next {
          right: 20px;
        }
      }
    }
    .info-detail {
      flex: 1;
      display: flex;
      padding: 0 24px;
      overflow: hidden;
      ::v-deep .el-scrollbar__view {
        height: 100%;
      }
      .pdf-data, .pdf-view {
        height: 100%;
      }
      .pdf-data {
        width: 575px;
        /*background-color: #ddf3ce;*/
        padding-top: 16px;
        margin-right: 16px;
        .pdf-data__content {
          .implant-wrap {
            .implant-header {
              .brand-info {
                display: flex;
                align-items: center;
                img {
                  width: 92px;
                }
                .brand-model {
                  @include fs(#606266, 14px, 400);
                  margin-left: 6px;
                }
              }
            }
          }
          .control-wrap {
            .control-header {
              .control-title {
                .title-content {
                  display: flex;
                  .icon {
                    @include domSize(24px, 24px);
                    background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-header.png") no-repeat;
                    background-size: 100% 100%;
                    margin-right: 4px;
                  }
                  .text {
                    @include fs(#0F1114, 16px, 500);
                  }
                }
                .verify-date {
                  @include fs(#606266, 14px, 400);
                  &::before {
                    content: ' ';
                    display: inline-block;
                    @include domSize(1px, 12px);
                    background-color: #C1CBDB;
                    margin: 0 4px;
                  }
                  margin-right: 60.5px;
                }
                .verify-status {
                  @include fs(#E6A23C, 12px, 400);
                  &__icon {
                    @include domSize(14px, 14px);
                    background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-warning.svg") no-repeat;
                    background-size: 100% 100%;
                  }
                }
              }
            }
            .control-body {
              .device-info {
                .device-container {
                  padding-bottom: 12px;
                  border-bottom: 1px solid #DCDFE6;
                  .device-header {
                    height: 20px;
                    display: flex;
                    align-items: center;
                    @include fs(#909399, 12px, 400);
                    .device-params {
                      /*width: calc(100% / 3);*/
                      // text-align: center;
                      &:first-of-type {
                        text-align: left;
                        padding-left: 60px;
                      }
                    }
                  }
                  .device-content {
                    .device-item {
                      width: 100%;
                      height: 20px;
                      display: flex;
                      align-items: center;
                      .params-key {
                        width: 60px;
                        text-align: center;
                      }
                      .device-column {
                        display: flex;
                        align-items: center;
                        // justify-content: center;
                        width: calc(100% / 3);
                        @include fs(#131414, 12px, 400);
                        &:first-of-type {
                          /*justify-content: center;*/
                          padding: 0 16px;
                        }
                      }
                    }
                  }
                }
                .device-model {
                  border-bottom: 1px solid #DCDFE6;
                  height: 44px;
                  display: flex;
                  align-items: center;
                  .model-item {
                    flex: 1;
                    padding-left: 16px;
                    display: flex;
                    @include fs (#131414, 12px, 400);
                    .item-label {
                      color: #909399;
                      margin-right: 12px;
                    }
                  }
                }
                .device-rate {
                  border-bottom: 1px solid #DCDFE6;
                  .rate-container {
                    // padding: 12px 0;
                    .rate-line {
                      display: flex;
                      margin-bottom: 8px;
                      .rate-item {
                        flex: 1;
                        padding-left: 16px;
                        display: flex;
                        @include fs (#131414, 12px, 400);
                        .item-label {
                          color: #909399;
                          margin-right: 12px;
                        }
                      }
                    }
                  }
                }
                .device-alert {
                  border-bottom: 1px solid #DCDFE6;
                  .alert-container {
                    padding: 0 0 12px 16px;
                    @include fs(#0F1114, 12px, 400);
                  }
                }
                .device-log {
                  .log-container {
                    padding: 0 0 12px 16px;
                    @include fs(#0F1114, 12px, 400);
                    .change-box {
                      display: flex;
                      margin-bottom: 10px;
                      .change-item {
                        width: 160px;
                      }
                      .item-icon {
                        width: 30px;
                      }
                      .item-title {
                        width: 200px;
                      }
                    }
                  }
                }
              }
            }
          }
          .files-wrap {
            .files-header {
              .files-title {
                display: flex;
                .icon {
                  @include domSize(24px, 24px);
                  background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-other-files.svg") no-repeat;
                  background-size: 100% 100%;
                  margin-right: 4px;
                }
                .text {
                  @include fs(#0F1114, 16px, 500);
                }
              }
              .edit-btn {
                justify-items: right;
                @include domSize(64px, 24px);
                padding: 0;
                color: #409EFF;
                border: 1px solid #409EFF;
              }
            }
            .files-body {
              padding: 16px;
              .files {
                &-item {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border: 1px solid #DCDFE6;
                  border-radius: 4px;
                  margin-bottom: 8px;
                  padding: 0 12px;
                  cursor: pointer;
                  @include domSize(100%, 60px);
                  .item-info {
                    display: flex;
                    &__icon {
                      @include domSize(36px, 36px);
                      margin-right: 8px;
                      img {
                        @include domSize(100%, 100%);
                      }
                    }
                    &__desc {
                      .desc-title {
                        @include fs(#131414, 14px, 400);
                      }
                      .desc-size {
                        @include fs(#606266, 12px, 400);
                      }
                    }
                  }
                  .item-preview {
                    background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-preview.svg") no-repeat;
                    background-size: 100% 100%;
                    @include domSize(12.83px, 9.92px);
                  }
                }
              }
              .empty {
                height: 106px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include fs(#909399, 14px, 400);
              }
            }
          }
          .recommend-wrap {
            background-color: #ffffff;
            .recommend-header {
              height: 44px;
              padding-left: 16px;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #E7E8EA;
              @include fs(#0F1114, 16px, 500);
              .recommend-icon {
                display: block;
                margin-right: 12px;
                @include domSize(21.97px, 24px);
              }
            }
            .recommend-body {
              height: 100px;
              padding: 8px 16px;
              @include fs(#909399, 12px, 400);
            }
          }
        }
      }
      .pdf-view {
        height: 800px;
        /*height: auto;*/
        flex: 1;
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        .form-comp__header {
          flex-shrink: 0;
          height: 44px;
          border: 1px solid #E4E7ED;
          border-bottom: none;
          border-radius: 4px 4px 0 0;
          display: flex;
          align-items: center;
          padding-left: 16px;
          /*border: none;*/
          border-bottom: 1px solid #E4E7ED;
          .header-icon {
            @include domSize(24px, 24px);
            margin-right: 6px;
            background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-proofread-header_pdf.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        .pdf-view__content {
          overflow: hidden;
          flex: 1;
          border: 1px solid #E7E8EA;
          border-top: none;
          .pdf-view__body {
            height: 100%;
          }
          .pdf-loading {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: 50px;
            }
          }
          .pdf-frame{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.common-wrap {
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #E7E8EA;
  margin-bottom: 20px;
  .common-header {
    display: flex;
    padding: 0 16px;
    align-items: center;
    border-bottom: 1px solid #E7E8EA;
    /*background-color: #F2F6FC;*/
    justify-content: space-between;
    @include domSize(100%, 44px);
    @include fs(#131414, 16px, 500);
  }
  .common-body {
    padding: 16px;
    background-color: #fff;
    box-sizing: border-box;
  }
}
.check-more {
  cursor: pointer;
  @include fs(#909399, 14px, 400);
  &::after {
    content: ' ';
    display: inline-block;
    @include domSize(5.34px, 10.14px);
    background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-arrowRight.svg") no-repeat;
    background-size: 100% 100%;
    margin-left: 8.33px;
  }
}
.device-common_title {
  line-height: 22px;
  height: 22px;
  margin: 12px 0 0 16px;
  @include fs(#606266, 13px, 500);
}
.follow-up-info {
  border-bottom: 1px solid #DCDFE6;
  .info-title {
    line-height: 32px;
    height: 32px;
    padding:7px 16px;
    @include fs(#909399, 13px, 500);
  }
  .info-container {
    padding: 2px 0 12px 16px;
    @include fs(#131414, 12px, 400);
  }

}
.drawer-footer {
      flex: none;
      padding: 16px 24px;
      border-top: 1px solid #e0e0e0;
      text-align: right;
      height: 64px;
      .cancel {
        width: 74px;
        margin-left: 12px;
      }
      .confirm {
        width: 74px;
      }
    }
</style>
<style>
  .my-label {
    width: 55px;
    color: #909399;
    font-size: 12px;
  }
  .my-content {
    color: #131414;
    font-size: 12px;
  }
</style>
