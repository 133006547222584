<template>
  <div class="empty" :style="{width, height}">
    <div class="empty-box">
      <div v-if="showImg" class="empty-box__img">
        <img src="https://f-u-system.oss-cn-beijing.aliyuncs.com/bg-empty.svg" alt="">
      </div>
      <div class="empty-box__text">{{text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    showImg: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
}
</script>

<style scoped lang="scss">
.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &-box {
    &__img {
      width: 160px;
      height: 173px;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__text {
      text-align: center;
      font-size: 14px;
      color: #909399;
    }
  }
}
</style>
