<template>
  <table class="form-comp" style="border-collapse: collapse; border: 1px #E4E7ED; width: 100%;">
    <div v-for="(item, index) in formData" :key="index">
      <!--    有层级， 标题    -->
      <div v-if="item.type === 'category'">
        <tr class="comp-title" >
          <td class="comp-row" colspan="4" style="width: 100%; padding: 5px 16px;">
            <div class="comp-cell">{{item.name}}</div>
          </td>
          <td class="empty-row">
          </td>
          <td class="empty-row">
          </td>
          <td class="empty-row">
          </td>
        </tr>
        <CompleteItem :report-id="reportId" :form-data="item.nodes"></CompleteItem>
      </div>
      <div v-if="item.type === 'data'">
        <tr v-if="item.valueType === 1" class="comp-single">
          <td class="comp-row" style="width: 35%">
            <el-popover
              placement="top-start"
              width="auto"
              trigger="hover"
              :content="item.source">
              <span v-if="item.source && item.source !== ''" slot="reference" style="cursor: pointer">{{item.name}}</span>
            </el-popover>
            <div v-if="!item.source">{{item.name}}</div>
          </td>
          <td class="comp-row" style="width: 28%">
            <div class="comp-cell">
              <div v-if="item.disabled && item.value && item.value.length > 10">
                <el-popover
                  placement="top-start"
                  title=""
                  width="200"
                  trigger="hover"
                  :content="item.value">
                  <el-input slot="reference" :disabled="item.disabled" v-model="item.value" size="mini">00</el-input>
                </el-popover>
              </div>
              <div v-else>
                <el-input :disabled="item.disabled" v-model="item.value" size="mini"></el-input>
              </div>
            </div>
          </td>
          <td class="comp-row" style="width: 14%">
            <div class="comp-cell">
              <el-input :disabled="item.disabled" class="comp-unit" size="mini" style="visibility: hidden"></el-input>
            </div>
          </td>
          <td class="comp-row" style="width: 23%; text-align: center">
            <el-button v-if="item.disabled" type="text" @click="edit(item)">修改数据</el-button>
            <el-button style="width: 75px" size="mini" v-else type="primary" @click="save(item)">保存</el-button>
          </td>
        </tr>
        <tr v-if="item.valueType === 2" class="comp-single">
          <td class="comp-row" style="width: 35%">
            <el-popover
              placement="top-start"
              width="auto"
              trigger="hover"
              :content="item.source">
              <span v-if="item.source && item.source !== ''" slot="reference" style="cursor: pointer">{{item.name}}</span>
            </el-popover>
            <div v-if="!item.source">{{item.name}}</div>
          </td>
          <td class="comp-row" style="width: 28%">
            <div class="comp-cell">
              <el-input :disabled="item.disabled" v-model="item.value" size="mini"></el-input>
            </div>
          </td>
          <td class="comp-row" style="width: 14%">
            <div class="comp-cell">
              <el-input :disabled="item.disabled" class="comp-unit" v-model="item.unit" size="mini"></el-input>
            </div>
          </td>
          <td class="comp-row" style="width: 23%; text-align: center">
            <el-button v-if="item.disabled" type="text" @click="edit(item)">修改数据</el-button>
            <el-button style="width: 75px" size="mini" v-else type="primary" @click="save(item)">保存</el-button>
          </td>
        </tr>
        <tr v-if="item.valueType === 3" class="comp-single">
          <td class="comp-row" style="width: 35%" colspan="2">
            <el-popover
              placement="top-start"
              width="auto"
              trigger="hover"
              :content="item.source">
              <span v-if="item.source && item.source !== ''" slot="reference" style="cursor: pointer">{{item.name}}</span>
            </el-popover>
            <div v-if="!item.source">{{item.name}}</div>
          <td class="comp-row" style="width: 42%; padding-left: 6px; padding-right: 6px;">
            <el-input :disabled="item.disabled" class="comp-unit" style="width: 25%; margin-right: 2px" v-model="item.values[0].value" size="mini"></el-input>
            <el-input :disabled="item.disabled" class="comp-unit" style="width: 16%; margin-right: 2px" v-model="item.values[0].unit" size="mini"></el-input>
            {{item.seps[0]}}
            <el-input :disabled="item.disabled" class="comp-unit" style="width: 25%; margin-right: 2px" v-model="item.values[1].value" size="mini"></el-input>
            <el-input :disabled="item.disabled" class="comp-unit" style="width: 16%" v-model="item.values[1].unit" size="mini"></el-input>
          </td>
          <td class="comp-row" style="width: 23%; text-align: center">
            <el-button v-if="item.disabled" type="text" @click="edit(item)">修改数据</el-button>
            <el-button style="width: 75px" v-else size="mini" type="primary" @click="save(item)">保存</el-button>
          </td>
        </tr>
        <tr v-if="item.valueType === 4" class="comp-single">
          <td class="comp-row" style="width: 35%" colspan="2">
            <el-popover
              placement="top-start"
              width="auto"
              trigger="hover"
              :content="item.source">
              <span v-if="item.source && item.source !== ''" slot="reference" style="cursor: pointer">{{item.name}}</span>
            </el-popover>
            <div v-if="!item.source">{{item.name}}</div>
          <td class="comp-row" style="width: 42%; padding-left: 6px; padding-right: 6px;">
            <el-input :disabled="item.disabled" class="comp-unit" style="width: 42%" v-model="item.values[0].value" size="mini"></el-input>
<!--            <el-input :disabled="item.disabled" class="comp-unit" style="width: 16%; margin-right: 2px" v-model="item.values[0].unit" size="mini"></el-input>-->
            <span> => </span>
            <el-input :disabled="item.disabled" class="comp-unit" style="width: 42%;" v-model="item.values[1].value" size="mini"></el-input>
<!--            <el-input :disabled="item.disabled" class="comp-unit" style="width: 16%" v-model="item.values[1].unit" size="mini"></el-input>-->
          </td>
          <td class="comp-row" style="width: 23%; text-align: center">
            <el-button v-if="item.disabled" type="text" @click="edit(item)">修改数据</el-button>
            <el-button style="width: 75px" v-else size="mini" type="primary" @click="save(item)">保存</el-button>
          </td>
        </tr>
      </div>
    </div>
  </table>
</template>

<script>
import { editReport } from '@/service/module/verify'

export default {
  name: 'CompleteItem',
  props: {
    formData: {
      type: Array,
      default: () => []
    },
    reportId: [Number, String]
  },
  methods: {
    edit (i) {
      i.disabled = false
    },
    async save (i) {
      console.log('修改：', i)
      const params = {
        reportId: this.reportId,
        ...i
      }
      const [err, res] = await this.$to(editReport(params))
      if (res && res.code === 200) {
        this.$message.success('编辑成功')
        i.disabled = true
      } else {
        this.$message.error('编辑失败')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .form-comp {
    .comp-header {
      border: 1px solid #E4E7ED;
      height: 44px;
    }
    .comp-row {
      border: 1px solid #E4E7ED;
      border-top: none;
      padding: 12px 16px;
      vertical-align: middle;
      &:first-of-type {
        border-left: none;
      }
      &:last-of-type {
        border-right: none;
      }
    }
    .empty-row {
      border-bottom: 1px solid #E4E7ED;
      padding: 12px 16px;
    }
    .comp-title {
      background: #F2F6FC;
      .comp-row {
        border-right: none;
      }
    }
    .comp-single {
    }
    .comp-cell {
      word-break: break-all;
    }
    .border-except-top {
      border: 1px solid #E4E7ED;
      border-top: none;
    }
    .comp-unit {
      ::v-deep .el-input__inner {
        padding: 0 2px;
        text-align: center;
      }
    }
    .confirm {
      background: #ECF5FF;
    }
    .mistake {
      background: #FCF6EC;
    }
    .empty {
      background: #F5F7FA;
    }
  }
</style>
