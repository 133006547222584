
export default {
  data () {
    return {
      genderFormat: '',
      tabList: ['随访历史', '随访计划', '基线信息', '心理量表', '随访数据对比表', 'ECG', '心脏彩超', 'Holter', '用药情况'],
      ciedModel: '',
      timeout: null,
      haveControl: false,
      havePlan: true,
      havePdf: false,
      showPdf: false,
      active: 0,
      tabActive: 0,
      activeRecordId: null
    }
  }
}
