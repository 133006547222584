<template>
  <drawer
    :visible.sync="openImplantView"
    title="植入信息"
    :width="720"
    cancel-str="关闭"
    confirm-str="编辑"
    @confirm="openImplantEditDrawer"
    @cancel="closeImplantViewDrawer">
    <div class="implant">
      <el-row>
        <el-col :span="12">
          <el-descriptions :colon="false" title="1、CIED植入信息" :column="1">
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="植入日期">{{moment.unix(viewData.implantDate / 1000).format('Y-MM-DD')}}</el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="医院">{{viewData.hospitalName}}</el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="术者">{{viewData.operator}}</el-descriptions-item>
          </el-descriptions>

          <el-descriptions :colon="false" title="2、CIED植入部位" :column="1">
            <el-descriptions-item  :colon="false" label-class-name="no-label" content-class-name="my-content" label=" ">
              {{viewData.ciedImplantSite > -1 ? implantSizeDict.find(o => o.code === viewData.ciedImplantSite).text : '-'}}
              <span v-if="viewData.ciedImplantSite === 3 && viewData.otherImplantSite">({{viewData.otherImplantSite}})</span>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions :colon="false" title="3、适应证" :column="1">
            <el-descriptions-item  :colon="false" label-class-name="no-label" content-class-name="my-content" label=" ">
              {{viewData.adaptationDiseaseLabel ? viewData.adaptationDiseaseLabel : '-'}}
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions :colon="false" title="4、CIED类型" :column="1">
            <el-descriptions-item  :colon="false" label-class-name="no-label" content-class-name="my-content" label=" ">
              {{viewData.ciedKindLabel ? viewData.ciedKindLabel : '-'}}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions :colon="false" title="5、CIED品牌" :column="1">
            <el-descriptions-item  :colon="false" label-class-name="no-label" content-class-name="my-content" label=" ">
              {{viewData.ciedBrandLabel ? viewData.ciedBrandLabel : '-'}}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions :colon="false" title="6、CIED型号" :column="1">
            <el-descriptions-item  :colon="false" label-class-name="no-label" content-class-name="my-content" label=" ">
              {{viewData.ciedModelLabel ? viewData.ciedModelLabel : '-'}}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions :colon="false" title="7、CIED序列号" :column="1">
            <el-descriptions-item  :colon="false" label-class-name="no-label" content-class-name="my-content" label=" ">
              {{viewData.ciedSn ? viewData.ciedSn : '-'}}
            </el-descriptions-item>
          </el-descriptions>
        </el-col>
        <el-col :span="12">
          <el-descriptions :colon="false" title="8、导线类型及参数" :column="1">
            <el-descriptions-item  :colon="false" label-class-name="no-label" content-class-name="my-content" label=" ">
              {{!viewData.showAtrial && !viewData.showRightAtrial && !viewData.showLeftAtrial ? '-' : ''}}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions v-if="viewData.showAtrial" :colon="false" :column="1">
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="导线类型">心房导线</el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="植入部位">
                {{viewData.leadParam.atrialImplantPosition > -1 ? atrialImplantPositionDict.find(o => o.code === viewData.leadParam.atrialImplantPosition).text : '-'}}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="导线型号">
                {{viewData.leadParam.atrialWireModel ? viewData.leadParam.atrialWireModel : '-'}}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="起搏阈值">
                {{viewData.leadParam.atrialPacingThresholdV ? viewData.leadParam.atrialPacingThresholdV : '-'}}
                V@
                {{viewData.leadParam.atrialPacingThresholdMs ? viewData.leadParam.atrialPacingThresholdMs : '-'}} ms
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="P波高度">
                {{viewData.leadParam.atrialPWaveAltitude ? viewData.leadParam.atrialPWaveAltitude : '-'}} mV
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="阻抗">
                {{viewData.leadParam.atrialImpedance ? viewData.leadParam.atrialImpedance : '-'}} Ω
              </el-descriptions-item>
          </el-descriptions>
          <el-divider v-if="viewData.showRightAtrial"></el-divider>
          <el-descriptions v-if="viewData.showRightAtrial" :colon="false" :column="1">
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="导线类型">右心室导线</el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="植入部位">
              {{viewData.leadParam.rightImplantPosition > -1 ? rightImplantPositionDict.find(o => o.code === viewData.leadParam.rightImplantPosition).text : '-'}}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="导线型号">
              {{viewData.leadParam.rightWireModel ? viewData.leadParam.rightWireModel : '-'}}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="起搏阈值">
              {{viewData.leadParam.rightPacingThresholdV ? viewData.leadParam.rightPacingThresholdV : '-'}}
              V@
              {{viewData.leadParam.rightPacingThresholdMs ? viewData.leadParam.rightPacingThresholdMs : '-'}} ms
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="R波高度">
              {{viewData.leadParam.rightRWaveAltitude ? viewData.leadParam.rightRWaveAltitude : '-'}} mV
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="阻抗">
              {{viewData.leadParam.rightImpedance ? viewData.leadParam.rightImpedance : '-'}} Ω
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="高压除颤阻抗">
              {{viewData.leadParam.rightHighVoltageImpedance ? viewData.leadParam.rightHighVoltageImpedance : '-'}} Ω
            </el-descriptions-item>
          </el-descriptions>
          <el-divider v-if="viewData.showLeftAtrial"></el-divider>
          <el-descriptions v-if="viewData.showLeftAtrial" :colon="false" :column="1">
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="导线类型">左心室导线</el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="植入部位">
              {{viewData.leadParam.leftImplantPosition > -1 ? leftImplantPositionDict.find(o => o.code === viewData.leadParam.leftImplantPosition).text : ''}}
              {{viewData.leadParam.leftOtherPosition ? ' - ' + viewData.leadParam.leftOtherPosition : ''}}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="导线型号">
              {{viewData.leadParam.leftWireModel ? viewData.leadParam.leftWireModel : '-'}}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="起搏阈值">
              {{viewData.leadParam.leftPacingThresholdV ? viewData.leadParam.leftPacingThresholdV : '-'}}
              V@
              {{viewData.leadParam.leftPacingThresholdMs ? viewData.leadParam.leftPacingThresholdMs : '-'}} ms
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="R波高度">
              {{viewData.leadParam.leftRWaveAltitude ? viewData.leadParam.leftRWaveAltitude: '-'}} mV
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="阻抗">
              {{viewData.leadParam.leftImpedance ? viewData.leadParam.leftImpedance : '-'}} Ω
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions :colon="false" title="9、起搏器参数设置" :column="1">
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="起搏模式">
              {{viewData.pacemakerModelLabel ? viewData.pacemakerModelLabel : '-'}}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="基线频率">
              {{viewData.bareLineHeartRateLabel ? viewData.bareLineHeartRateLabel: '-'}} bpm
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions :colon="false" title="10、围术期不良事件" :column="1">
            <el-descriptions-item label-class-name="no-label" content-class-name="my-content" label="">
              {{viewData.hasEventStr ? viewData.hasEventStr : viewData.adverseEventLabel}}
            </el-descriptions-item>
          </el-descriptions>
        </el-col>
      </el-row>
    </div>
    <drawer
      :visible.sync="openImplantEdit"
      :width="400"
      title="编辑植入信息"
      confirm-str="保存"
      cancel-str="取消"
      @cancel="closeImplantEditDrawer"
      @confirm="saveImplantData"
      :to-body="true"
      >
      <div class="edit">
        <el-form :model="editData" label-width="90px">
          <div class="form-title">1、CIED植入信息</div>
          <el-form-item label="植入日期" required>
            <el-date-picker
              size="small"
              :style="fixStyle"
              v-model="editData.implantDate"
              :disabled="true"
              value-format="timestamp"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="植入医院">
            <el-input size="small" v-model="editData.hospitalName" :style="fixStyle" placeholder="请填写植入医院"></el-input>
          </el-form-item>
          <el-form-item label="术者" required>
            <el-input class="last-margin" size="small" v-model="editData.operator" :style="fixStyle" placeholder="请填写术者"></el-input>
          </el-form-item>

          <div class="form-title">2、CIED植入部位</div>
          <el-form-item label-width="0">
            <el-radio-group  class="last-margin" v-model="editData.ciedImplantSite" @change="changeSite">
              <el-radio v-for="(i, k) in implantSizeDict" :key="k" :label="i.code">{{i.text}}</el-radio>
              <el-input style="margin-top: 5px; width: 100px" v-model="editData.otherImplantSite" v-if="editData.ciedImplantSite === 3" size="mini"></el-input>
            </el-radio-group>
          </el-form-item>

          <div class="form-title">3、适应证</div>
          <el-form-item label-width="0">
            <el-checkbox-group v-model="editData.adaptationDisease.indicationsSymptom" @change="changeAdaptation">
              <el-checkbox class="el-check-pinpai" :label="0">
                心动过缓
              </el-checkbox>
              <el-form-item label-width="90px">
                <el-radio-group v-if="editData.adaptationDisease.indicationsSymptom.indexOf(0) !== -1" v-model="editData.adaptationDisease.bradycardiaRadio" class="adaptation_content--1">
                  <el-radio style="display:block; height: 32px" :label="0">病态窦房结综合征</el-radio>
                  <el-radio style="display:block; height: 32px" :label="1">房室传导阻滞</el-radio>
                  <el-radio style="display:block; height: 32px" :label="2">房颤伴慢室率</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-checkbox class="el-check-pinpai" :label="1">
                心力衰竭
              </el-checkbox>
              <br>
              <el-checkbox class="el-check-pinpai" :label="2">
                室性心律失常
              </el-checkbox>
              <el-form-item label-width="90px">
                <el-radio-group v-if="editData.adaptationDisease.indicationsSymptom.indexOf(2) !== -1" v-model="editData.adaptationDisease.arrhythmiaRadio" class="adaptation_content--1">
                  <el-radio style="display:block; height: 32px" :label="0">室性心动过速</el-radio>
                  <el-radio style="display:block; height: 32px" :label="1">心室颤动</el-radio>
                  <el-radio style="display:block; height: 32px" :label="2">室性期前收缩</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-checkbox :label="3">
                其他
              </el-checkbox>
              <el-input
                :disabled="editData.adaptationDisease.indicationsSymptom.indexOf(3) === -1"
                v-model="editData.adaptationDisease.otherIndications"
                placeholder="请输入其他适应证"
                size="small"
                style="display: block; width: 288px;"
                maxlength="20">
              </el-input>
            </el-checkbox-group>
          </el-form-item>

          <div class="form-title">4、CIED类型</div>
          <el-form-item label-width="0">
            <el-radio-group v-model="editData.ciedKind.ciedTypeRadio" @change="changeCiedType">
              <el-radio style="width: 20%; height: 32px; line-height: 32px" v-for="(i, k) in ciedTypeDict" :key="k" :label="i.code">{{i.text}}</el-radio>
              <el-input size="small" style="margin-left: -40px; " :style="fixStyle" v-model="editData.ciedKind.otherCiedType" placeholder="请填写CIED类型"></el-input>
            </el-radio-group>
          </el-form-item>

          <div class="form-title" style="margin-top: 16px">5、CIED品牌</div>
          <el-form-item label-width="0">
            <el-radio-group v-model="editData.ciedBrand" @change="newChangeBrand">
              <el-radio v-for="(item, index) in brandDict" :style="radioFixStyle" :key="index" :label="item.code">{{item.name}}</el-radio>
              <el-input v-if="editData.ciedBrand === '5'" size="small" :style="fixStyle" v-model="editData.otherCiedBrand" placeholder="请输入其他品牌"></el-input>
            </el-radio-group>
          </el-form-item>
          <div class="form-title" style="margin-top: 16px">6、CIED型号</div>
          <el-form-item label-width="0">
            <el-select size="small" v-if="editData.ciedBrand && (editData.ciedBrand !== '5')" :style="fixStyle" filterable style="margin-right: 15px;" class="el-checkbox-input" allow-create v-model="editData.ciedModel" :placeholder="brandPlaceholder">
              <el-option
                v-for="(m, c) in modelDict"
                :key="c"
                :label="m.name"
                :value="m.code"
              >
              </el-option>
              <el-option label="其他" value="0"></el-option>
            </el-select>
            <el-input v-if="editData.ciedBrand === '5' || editData.ciedModel === '0'" :style="fixStyle" size="small" v-model="editData.otherCiedModel" placeholder="请输入其他型号" ></el-input>
          </el-form-item>
          <div class="form-title" style="margin-top: 16px">7、CIED序列号</div>
          <el-form-item label-width="0">
            <el-input v-if="editData.ciedBrand" v-model="editData.ciedSn" placeholder="请填写CIED序列号" size="small" :style="fixStyle"></el-input>
          </el-form-item>
          <div class="form-title" style="margin-top: 16px">8、导线类型及参数</div>
          <el-form-item label-width="0">
            <el-checkbox style="display: block" v-model="editData.leadParam.checkedAtrial" class="el-check-pinpai" label="心房导线" @change="changeLead"></el-checkbox>
            <div class="lead-atrial" v-if="editData.leadParam.checkedAtrial">
              <div class="item-title">
                植入部位:
              </div>
              <el-radio-group v-model="editData.leadParam.atrialImplantPosition">
                <el-radio :label="0">右心耳</el-radio>
                <el-radio :label="1">右心房侧壁</el-radio>
              </el-radio-group>
              <br>
              <div class="item-title">导线型号:</div>
              <el-input size="small" :style="fixStyle" placeholder="请填写导线型号" v-model="editData.leadParam.atrialWireModel"></el-input>
              <br>
              <div class="item-title">
                起搏阈值:
              </div>
              <el-input size="small" :style="fixStyle" placeholder="请填写起搏阈值" v-model="editData.leadParam.atrialPacingThresholdV"></el-input>&nbsp;V&nbsp;
              <el-input size="small" :style="fixStyle" style="margin-left: 90px" placeholder="请填写起搏阈值" v-model="editData.leadParam.atrialPacingThresholdMs"></el-input>&nbsp;ms
              <div class="item-title">
                P波高度:
              </div>
              <el-input size="small" :style="fixStyle" placeholder="请填写P波高度" v-model="editData.leadParam.atrialPWaveAltitude"></el-input>&nbsp;mV&nbsp;
              <div class="item-title">
                阻抗:
              </div>
              <el-input size="small" :style="fixStyle" placeholder="请填写阻抗" v-model="editData.leadParam.atrialImpedance"></el-input>&nbsp;Ω&nbsp;
            </div>
            <el-checkbox v-model="editData.leadParam.checkedRightVentricular" class="el-check-pinpai" label="右心室导线" @change="changeLead"></el-checkbox>
            <div class="lead-right" v-if="editData.leadParam.checkedRightVentricular">
              <div class="item-title">植入部位:</div>
              <el-radio-group style="margin-left: 90px" v-model="editData.leadParam.rightImplantPosition">
                <el-radio style="height: 32px" :label="0">右室心尖部</el-radio>
                <el-radio style="height: 32px" :label="1">右室间隔部</el-radio>
                <el-radio style="height: 32px" :label="2">希氏束区域</el-radio>
                <el-radio style="height: 32px" :label="3">左束支区域</el-radio>
              </el-radio-group>
              <div class="item-title">导线型号:</div>
              <el-input size="small" :style="fixStyle" placeholder="请填写导线型号" v-model="editData.leadParam.rightWireModel"></el-input>
              <div class="item-title">起搏阈值:</div>
              <el-input size="small" :style="fixStyle" placeholder="请填写起搏阈值" v-model="editData.leadParam.rightPacingThresholdV"></el-input>&nbsp;V&nbsp;
              <el-input style="margin-left: 90px" size="small" :style="fixStyle" placeholder="请填写起搏阈值" v-model="editData.leadParam.rightPacingThresholdMs"></el-input>&nbsp;ms&nbsp;
              <div class="item-title">R波高度:</div>
              <el-input size="small" :style="fixStyle" placeholder="请填写R波高度" v-model="editData.leadParam.rightRWaveAltitude"></el-input>&nbsp;mV&nbsp;
              <div class="item-title">阻抗:</div>
              <el-input size="small" :style="fixStyle" placeholder="请填写阻抗" v-model="editData.leadParam.rightImpedance"></el-input>&nbsp;Ω&nbsp;
              <div class="item-title">高压除颤阻抗:</div>
              <el-input size="small" :style="fixStyle" placeholder="请填写高压除颤阻抗" v-model="editData.leadParam.rightHighVoltageImpedance"></el-input>&nbsp;Ω&nbsp;
            </div>
            <el-checkbox style="display: block" v-model="editData.leadParam.checkedLeftVentricular" class="el-check-pinpai" label="左心室导线" @change="changeLead"></el-checkbox>
            <div class="lead-left" v-if="editData.leadParam.checkedLeftVentricular">
              <div class="item-title">植入部位:</div>
              <el-radio-group @change="leftPositionChange" style="margin-left: 90px" v-model="editData.leadParam.leftImplantPosition">
                <el-radio style="height: 32px" :label="0">侧后静脉</el-radio>
                <el-radio style="height: 32px" :label="1">侧静脉</el-radio>
                <el-radio style="height: 32px" :label="2">其他</el-radio>
                <el-input
                  class="el-checkbox-input-left"
                  size="small"
                  :style="fixStyle"
                  placeholder="请输入内容"
                  v-show="editData.leadParam.leftImplantPosition === 2"
                  v-model="editData.leadParam.leftOtherPosition"
                  minlength="2"
                  maxlength="20">
                </el-input>
              </el-radio-group>
              <div class="item-title">导线型号:</div>
              <el-input size="small" :style="fixStyle" placeholder="请填写导线型号" v-model="editData.leadParam.leftWireModel"></el-input>
              <div class="item-title">起搏阈值:</div>
              <el-input size="small" :style="fixStyle" placeholder="请填写起搏阈值" v-model="editData.leadParam.leftPacingThresholdV"></el-input>&nbsp;V&nbsp;
              <el-input style="margin-left: 90px" size="small" :style="fixStyle" placeholder="请填写起搏阈值" v-model="editData.leadParam.leftPacingThresholdMs"></el-input>&nbsp;ms&nbsp;
              <div class="item-title">R波高度:</div>
              <el-input size="small" :style="fixStyle" placeholder="请填写R波高度" v-model="editData.leadParam.leftRWaveAltitude"></el-input>&nbsp;mV&nbsp;
              <div class="item-title">阻抗:</div>
              <el-input size="small" :style="fixStyle" placeholder="请填写阻抗" v-model="editData.leadParam.leftImpedance"></el-input>&nbsp;Ω&nbsp;
            </div>
          </el-form-item>

          <div class="form-title" style="margin-top: 16px">9、起搏器参数设置</div>
          <el-form-item label-width="0">
            <el-form-item label="起搏模式">
              <el-radio-group v-model="editData.pacemakerParam.pacemakerModel">
                <el-radio style="width: 20%; height: 32px; line-height: 32px" v-for="(i, k) in pacemakerModelDict" :key="k" :label="i.code">{{i.text}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="基线频率">
              <el-input size="small" :style="fixStyle" v-model="editData.pacemakerParam.bareLineHeartRate" placeholder="请填写基线频率"></el-input> bpm
            </el-form-item>
          </el-form-item>

          <div class="form-title" style="margin-top: 16px">10、围术期不良事件</div>
          <el-form-item label-width="0">
            <el-radio-group v-model="editData.adverseEvent.hasEvent" @change="hasEventChange">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label-width="0">
            <el-checkbox-group v-if="editData.adverseEvent.hasEvent === 1" v-model="editData.adverseEvent.events" @change="hasOtherEvent">
              <el-checkbox v-for="(i, k) in adverseEventDict" :key="k" :label="i.code">{{i.text}}</el-checkbox>
              <el-input v-if="editData.adverseEvent.events.indexOf(9) !== -1" v-model="editData.adverseEvent.otherEvent" size="small" style="margin-left: 10px" :style="fixStyle" placeholder="请填写其他不良事件"></el-input>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </drawer>
  </drawer>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer'
import eventBus from '@/libs/utils/eventBus'
import { getImplant, getDictionary, saveImplant, getCiedTpl } from '@/service/module/followup'
import {
  implantSizeDict,
  indicationsDict,
  bradycardiaRadioDict,
  arrhythmiaRadioDict,
  ciedTypeDict,
  atrialImplantPositionDict,
  rightImplantPositionDict,
  leftImplantPositionDict,
  pacemakerModelDict,
  hasEventDict,
  adverseEventDict
} from '@/utils/enums'
import _ from 'lodash'
const moment = require('moment')

export default {
  components: {
    Drawer
  },
  data () {
    return {
      openImplantView: false,
      openImplantEdit: false,
      brandDict: [],
      modelDict: [],
      viewData: {},
      editData: {
        implantDate: '',
        adaptationDisease: {
          indicationsSymptom: [],
          bradycardiaRadio: null,
          otherIndications: '',
          arrhythmiaRadio: null
        },
        ciedBrand: '',
        ciedModel: '',
        otherCiedBrand: '',
        otherCiedModel: '',
        leadParam: {
          checkedAtrial: false,
          checkedLeftVentricular: false,
          checkedRightVentricular: false,
          atrialImplantPosition: -1,
          atrialWireModel: '',
          atrialPacingThresholdV: '',
          atrialPacingThresholdMs: '',
          atrialPWaveAltitude: '',
          atrialImpedance: '',
          rightImplantPosition: -1,
          rightWireModel: '',
          rightPacingThresholdV: '',
          rightPacingThresholdMs: '',
          rightRWaveAltitude: '',
          rightHighVoltageImpedance: '',
          rightImpedance: '',
          leftOtherPosition: '',
          leftImplantPosition: -1,
          leftWireModel: '',
          leftPacingThresholdV: '',
          leftPacingThresholdMs: '',
          leftRWaveAltitude: '',
          leftImpedance: ''
        },
        pacemakerParam: {
          checkedPacemakerModel: false,
          bareLineHeartRate: '',
          checkedBareLineHeartRate: false,
          pacemakerModel: 0
        },
        adverseEvent: {
          hasEvent: -1,
          events: [],
          otherEvent: ''
        },
        ciedKind: {
          ciedTypeRadio: -1,
          otherCiedType: ''
        }
      },
      moment,
      indicationsDict,
      implantSizeDict,
      ciedTypeDict,
      atrialImplantPositionDict,
      rightImplantPositionDict,
      leftImplantPositionDict,
      pacemakerModelDict,
      adverseEventDict,
      fixStyle: {
        width: '213px'
      },
      radioFixStyle: {
        width: '66px',
        marginBottom: '16px'
      },
      brandList: [],
      modelList: []
    }
  },
  mounted () {
    this.getDict()
    this.getBrand()
  },
  computed: {
    brandPlaceholder () {
      let placeholder = ''
      switch (this.editData.ciedBrand) {
        case '1': placeholder = '请选择美敦力型号'
          break
        case '2': placeholder = '请选择雅培型号'
          break
        case '3': placeholder = '请选择百多力型号'
          break
        case '4': placeholder = '请选择波科型号'
          break
        default: placeholder = ''
          break
      }
      return placeholder
    }
  },
  methods: {
    async getDict () {
      const [err, res] = await this.$to(getDictionary('ciedBrand'))
      if (res && res.code === 200) {
        this.brandDict = res.data
      } else {
        this.$message.error('品牌列表获取失败')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    async getData () {
      const [err, res] = await this.$to(getImplant(this.$route.query.patientId))
      if (res && res.code === 200) {
        this.viewData = res.data
        this.editData = _.cloneDeep(res.data)

        /**
         *  品牌编辑反显
         * */
        if (this.editData.ciedBrand && this.editData.ciedBrand !== '5') {
          this.modelDict = this.brandDict.find(o => o.code === this.editData.ciedBrand).models
        }

        /**
         *  处理数据映射文案
         * */
        // 适应证
        let fitsChild = ''
        let secondChild = ''
        const adaptationDiseaseStrList = []
        const adaptationDisease = _.cloneDeep(this.viewData.adaptationDisease)
        // 处理心动过缓下级选项
        if (this.viewData.adaptationDisease.bradycardiaRadio !== null) {
          fitsChild = bradycardiaRadioDict.find(o => o.code === adaptationDisease.bradycardiaRadio).text
        }
        // 处理心律失常下级选项
        if (this.viewData.adaptationDisease.arrhythmiaRadio !== null) {
          secondChild = arrhythmiaRadioDict.find(o => o.code === adaptationDisease.arrhythmiaRadio).text
        }
        for (let i = 0; i < adaptationDisease.indicationsSymptom.length; i++) {
          let tempStr = indicationsDict.find(o => o.code === adaptationDisease.indicationsSymptom[i]).text
          if (adaptationDisease.indicationsSymptom[i] === 0) { // 选中心率过缓
            if (fitsChild) {
              tempStr = `${tempStr}(${fitsChild})`
            }
          } else if (adaptationDisease.indicationsSymptom[i] === 2) { // 选中心率时失常
            if (secondChild) {
              tempStr = `${tempStr}(${secondChild})`
            }
          } else if (adaptationDisease.indicationsSymptom[i] === 3) { // 选中其他
            if (adaptationDisease.otherIndications) {
              tempStr = `${tempStr}(${adaptationDisease.otherIndications})`
            }
          }
          adaptationDiseaseStrList.push(tempStr)
          // adaptationDiseaseStrList.push(indicationsDict.find(o => o.code === adaptationDisease.indicationsSymptom[i]).text)
        }
        this.viewData.adaptationDiseaseLabel = adaptationDiseaseStrList.join('、')

        // 处理CIED类型
        if (this.viewData.ciedKind.ciedTypeRadio >= 0) {
          if (this.viewData.ciedKind.otherCiedType) {
            this.viewData.ciedKindLabel = this.viewData.ciedKind.otherCiedType
          } else {
            this.viewData.ciedKindLabel = ciedTypeDict.find(o => o.code === this.viewData.ciedKind.ciedTypeRadio).text
          }
        }
        console.log('植入:', this.viewData)

        // 品牌
        if (this.viewData.ciedBrand) {
          let brandStr,
            detailStr
          if (this.viewData.ciedBrand === '5') {
            brandStr = this.viewData.otherCiedBrand
            detailStr = this.viewData.otherCiedModel
          } else {
            brandStr = this.brandDict.find(o => o.code === this.viewData.ciedBrand).name
            if (this.viewData.ciedModel) {
              if (this.viewData.ciedModel === '0') {
                detailStr = this.viewData.otherCiedModel
              } else {
                const brandList = this.brandDict.find(o => o.code === this.viewData.ciedBrand).models
                detailStr = brandList.find(o => o.code === this.viewData.ciedModel).name
              }
            } else {
              detailStr = ''
            }
          }
          this.viewData.ciedBrandLabel = brandStr
          this.viewData.ciedModelLabel = detailStr
        }

        // 处理导线
        this.viewData.showAtrial = res.data.leadParam.checkedAtrial
        this.viewData.showRightAtrial = res.data.leadParam.checkedRightVentricular
        this.viewData.showLeftAtrial = res.data.leadParam.checkedLeftVentricular

        // 起搏器参数设置
        if (this.viewData.pacemakerParam.pacemakerModel) {
          this.viewData.pacemakerModelLabel = pacemakerModelDict.find(o => o.code === this.viewData.pacemakerParam.pacemakerModel).text
        }
        this.viewData.bareLineHeartRateLabel = this.viewData.pacemakerParam.bareLineHeartRate

        // 围术期不良事件
        const adverseEventList = []
        const events = this.viewData.adverseEvent.events
        for (let i = 0; i < events.length; i++) {
          let eventStr = ''
          eventStr = adverseEventDict.find(o => o.code === events[i]).text
          if (events[i] === 9 && this.viewData.adverseEvent.otherEvent) {
            eventStr += `(${this.viewData.adverseEvent.otherEvent})`
          }
          adverseEventList.push(eventStr)
        }
        this.viewData.adverseEventLabel = adverseEventList.join('、')

        if (this.viewData.adverseEvent.hasEvent !== 1) {
          this.viewData.hasEventStr = hasEventDict.find(o => o.code === this.viewData.adverseEvent.hasEvent).text
        } else {
          this.viewData.hasEventStr = ''
        }
      } else {
        this.$message.error('植入信息获取失败')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    async getBrand () {
      const [err, res] = await this.$to(getCiedTpl())
      if (res && res.code === 200) {
        console.log('品牌:', res)
        this.brandList = res.data
      } else {
        this.$message.error(res.message)
      }

      if (err) {
        this.$message.error(err.message)
        throw new Error(err.message)
      }
    },
    openImplantViewDrawer () {
      this.openImplantView = true
      this.getData()
    },
    closeImplantViewDrawer () {
      this.openImplantView = false
    },
    openImplantEditDrawer () {
      this.openImplantEdit = true
    },
    closeImplantEditDrawer () {
      this.openImplantEdit = false
      this.getData()
    },
    async saveImplantData () {
      if (!this.editData.operator) {
        this.$message.error('请填写术者')
        return false
      }
      console.log('32321:', this.editData)

      const [err, res] = await this.$to(saveImplant(this.editData))
      if (res && res.code === 200) {
        console.log('保存植入:', res)
        this.$message.success('保存成功')
        this.closeImplantEditDrawer()
        eventBus.$emit('saveImplant')
      } else {
        this.$message.error('保存失败')
      }
      if (err) {
        this.$message.error(err)
      }
    },
    hasEventChange (val) {
      if (val === 0) {
        this.editData.adverseEvent.events = []
        this.editData.adverseEvent.otherEvent = ''
      }
    },
    hasOtherEvent (val) {
      if (val.indexOf(9) === -1) {
        this.editData.adverseEvent.otherEvent = ''
      }
    },
    leftPositionChange (val) {
      if (val !== 2) {
        this.editData.leadParam.leftOtherPosition = ''
      }
    },
    changeAdaptation (val) {
      if (val.indexOf(0) === -1) {
        this.editData.adaptationDisease.bradycardiaRadio = null
      }
      if (val.indexOf(3) === -1) {
        this.editData.adaptationDisease.otherIndications = ''
      }
      if (val.indexOf(2) === -1) {
        this.editData.adaptationDisease.arrhythmiaRadio = null
      }
    },
    changeCiedType (val) {
      if (val !== 6) {
        this.editData.ciedKind.otherCiedType = ''
      }
    },
    changeCiedBrand (val) {
      this.editData.ciedBrand.key = val
      switch (val) {
        case 0:
          this.editData.ciedBrand.yapeitext = ''
          this.editData.ciedBrand.bailiduotext = ''
          this.editData.ciedBrand.boketext = ''
          this.editData.ciedBrand.qitatext = ''
          break
        case 1:
          this.editData.ciedBrand.bailiduotext = ''
          this.editData.ciedBrand.boketext = ''
          this.editData.ciedBrand.qitatext = ''
          this.editData.ciedBrand.model = null
          break
        case 2:
          this.editData.ciedBrand.yapeitext = ''
          this.editData.ciedBrand.boketext = ''
          this.editData.ciedBrand.qitatext = ''
          this.editData.ciedBrand.model = null
          break
        case 3:
          this.editData.ciedBrand.yapeitext = ''
          this.editData.ciedBrand.bailiduotext = ''
          this.editData.ciedBrand.qitatext = ''
          this.editData.ciedBrand.model = null
          break
        case 4:
          this.editData.ciedBrand.yapeitext = ''
          this.editData.ciedBrand.boketext = ''
          this.editData.ciedBrand.bailiduotext = ''
          this.editData.ciedBrand.model = null
          break
      }
    },
    newChangeBrand (val) {
      this.editData.ciedBrand = val
      if (this.editData.ciedBrand !== '5') {
        const curOpt = this.brandDict.find(i => i.code === val)
        console.log(curOpt)
        this.modelDict = curOpt.models
        this.editData.otherCiedBrand = ''
        this.editData.ciedModel = ''
      } else {
        this.editData.ciedModel = '0'
      }
      this.editData.otherCiedModel = ''
    },
    changeLead () {
      if (!this.editData.leadParam.checkedAtrial) {
        this.editData.leadParam.atrialImplantPosition = -1
        this.editData.leadParam.atrialWireModel = ''
        this.editData.leadParam.atrialPacingThresholdV = ''
        this.editData.leadParam.atrialPacingThresholdMs = ''
        this.editData.leadParam.atrialPWaveAltitude = ''
        this.editData.leadParam.atrialImpedance = ''
      }
      if (!this.editData.leadParam.checkedRightVentricular) {
        this.editData.leadParam.rightImplantPosition = -1
        this.editData.leadParam.rightWireModel = ''
        this.editData.leadParam.rightPacingThresholdV = ''
        this.editData.leadParam.rightPacingThresholdMs = ''
        this.editData.leadParam.rightRWaveAltitude = ''
        this.editData.leadParam.rightHighVoltageImpedance = ''
        this.editData.leadParam.rightImpedance = ''
      }
      if (!this.editData.leadParam.checkedLeftVentricular) {
        this.editData.leadParam.leftOtherPosition = ''
        this.editData.leadParam.leftImplantPosition = -1
        this.editData.leadParam.leftWireModel = ''
        this.editData.leadParam.leftPacingThresholdV = ''
        this.editData.leadParam.leftPacingThresholdMs = ''
        this.editData.leadParam.leftRWaveAltitude = ''
        this.editData.leadParam.leftImpedance = ''
      }
    },
    changeSite (val) {
      if (val !== 3) {
        this.editData.otherImplantSite = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../style/common";
  .implant {
    ::v-deep .no-label {
      width: 0;
      margin-right: 0;
    }
    ::v-deep .my-label {
      width: 90px;
    }
    ::v-deep .el-descriptions__header {
      margin-bottom: 8px;
    }
    ::v-deep .el-divider {
      margin-top: 0;
      margin-bottom: 8px;
    }
    ::v-deep .el-descriptions-item__cell {
      padding-bottom: 8px;
    }
  }
  .edit {
    .form-title {
      @include fs(#131414, 14px , 400);
      margin-bottom: 8px;
    }
    .last-margin {
      margin-bottom: 16px;
    }
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
    .item-title {
      display: inline-block;
      width: 90px;
    }
  }
</style>
