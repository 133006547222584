<template>
  <div class="plan-view">
    <div v-if="addManualPeriodHandle" class="item add-item flex-center" @click="addNewItem">
      <div class="add-item__text">新增随访 + </div>
      <!-- <div class="add-item__icon"></div> -->
    </div>
    <div v-for="(i, k) in planList" :key="k" class="item">
      <div class="item-icon"></div>
      <div class="item-name">
        {{i.name}}
<!--        <div v-if="i.isNew" class="item-name_add"></div>-->
      </div>
      <div class="item-status">
        <span v-if="i.status === 0">未开始</span>
        <span v-else-if="i.status === 1">进行中</span>
        <span v-else-if="i.status === 2">已完成</span>
        <span v-else-if="i.status === 3">已超窗</span>
      </div>
      <div class="item-date">{{moment.unix(i.visitStartTime / 1000).format('Y-MM-DD')}} - {{moment.unix(i.visitEndTime / 1000).format('Y-MM-DD')}}</div>
      <div class="item-from">{{i.creator}}</div>
      <div v-if="deleteManualPeriodHandle" class="item-delete" :class="i.type === 0 ? 'hide' : ''" @click="deleteMorePlan(i)"></div>
    </div>
    <drawer
      :visible.sync="openPlanAdd"
      :width="400"
      title="新增随访安排"
      confirm-str="保存"
      cancel-str="取消"
      @cancel="closePlanAddDrawer"
      @confirm="savePlanData"
    >
      <div class="plan-add">
        <el-form>
          <el-form-item label="随访日期">
            <el-date-picker
              :picker-options="pickerOptions"
              size="small"
              style="width: 285px"
              v-model="newDate"
              type="daterange"
              value-format="timestamp"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
    </drawer>
  </div>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer'
import { getPeriodList, manualPeriodAdd, manualPeriodDelete } from '@/service/module/followup'
const moment = require('moment')
export default {
  components: {
    Drawer
  },
  props: {
    patientId: [Number, String]
  },
  mounted () {
    this.getPeriodPlan()
    const authList = JSON.parse(sessionStorage.getItem('auth')).perms
    authList.find(i => {
      if (i === 'patient-mgr:add-manual-period') {
        this.addManualPeriodHandle = true
      }
      if (i === 'patient-mgr:delete-manual-period') {
        this.deleteManualPeriodHandle = true
      }
    })
  },
  data () {
    return {
      openPlanAdd: false,
      tableOpts: [
        {
          label: '随访名称',
          prop: 'name'
        },
        {
          label: '随访日期',
          prop: 'date'
        }
      ],
      planList: [],
      newDate: [],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      moment,

      addManualPeriodHandle: false,
      deleteManualPeriodHandle: false
    }
  },
  methods: {
    async getPeriodPlan () {
      const [err, res] = await this.$to(getPeriodList({
        patientId: this.patientId
      }))
      if (res && res.code === 200) {
        this.planList = res.data
        // const cbList = []
        // res.data.forEach(item => {
        //   if (item.type === 1) {
        //     cbList.push(item)
        //   }
        // })
        // const times = cbList.map(item => item.createAt)
        // const maxTime = Math.max(...times)
        // this.planList.forEach(item => {
        //   if (item.createAt === maxTime) {
        //     item.isNew = true
        //   } else {
        //     item.isNew = false
        //   }
        // })
        console.log('getPeriodPlan:', this.planList)
      } else {
        this.$message.error('获取患者随访计划失败')
      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    async savePlanData () {
      if (this.newDate.length === 0) {
        this.$message.error('请选择起止日期！')
        return false
      }

      this.$confirm('确认创建自建随访计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const [err, res] = await this.$to(manualPeriodAdd({
          patientId: this.patientId,
          from: this.newDate[0],
          to: this.newDate[1]
        }))
        if (res && res.code === 200) {
          await this.getPeriodPlan()
          this.$message({
            type: 'success',
            message: '创建成功!'
          })
          this.newDate = ''
          this.openPlanAdd = false
        } else {
          this.$message.error(res.message)
        }
        if (err) {
          this.$message.error('网络错误')
          throw new Error(err)
        }
      }).catch(() => {})
    },
    closePlanAddDrawer () {
      this.newDate = ''
      this.openPlanAdd = false
    },
    async deleteMorePlan (i) {
      console.log(i)
      const { periodId } = i
      this.$confirm('确认删除自建随访计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        manualPeriodDelete({ periodId: periodId }).then(res => {
          console.log('res:', res)
          if (res.code !== 200) {
            this.$message.error(res.message)
          } else {
            this.getPeriodPlan()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          }
        }).catch(err => {
          this.$message.error('网络错误')
          throw new Error(err)
        })
        // const [err, res] = this.$to(manualPeriodDelete({
        //   periodId: periodId
        // }))

        // if (res && res.code === 200) {

        // } else {
        //   this.$message.error('删除失败')
        // }
        // if (err) {
        //   console.log('1232312')

        // }
      }).catch(() => {})
    },
    addNewItem () {
      this._send('$click', '随访计划新增随访点击', '随访计划新增随访按钮 发生点击行为触发', {
        accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
        doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
        hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
      })
      this.openPlanAdd = true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../style/common";
.plan-view {
  padding: 16px 24px 16px;
  .item {
      @include domSize(100%, 52px);
      @include fs(#606266, 14px, 400);
      margin-bottom: 8px;
      border: 1px solid #E9E9EB;
      border-radius: 4px;
      display: flex;
      padding-left: 21.33px;
      align-items: center;
      &-icon {
        @include domSize(14px, 14px);
        background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon_more_plan_time.svg") no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
      }
      &-name {
        flex: 1;
        margin-right: 10px;
        display: flex;
        align-items: center;
        &_add {
          @include domSize(22px, 11px);
          margin-left: 4px;
          background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon_more_plan_new.svg") no-repeat;
          background-size: 100% 100%;
        }
      }
      &-status {
        flex: 1;
      }
      &-date {
        flex: 1;
        /*margin-right: 34px;*/
      }
      &-from {
        flex: 1;
        width: 166px;
        /*margin-right: 6px;*/
      }
      &-delete {
        @include domSize(26px, 26px);
        background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon_more_plan_delete.svg") no-repeat center;
        background-size: 50% 50%;
        cursor: pointer;
        margin-right: 24px;
        &.hide {
          visibility: hidden;
        }
      }

    }
  .add-item {
    cursor: pointer;
    background: #ECF5FF;
    border: 1px solid #409EFF;
    border-radius: 4px;
    &__text {
      @include fs(#409EFF, 14px, 400);
      line-height: 24px;
      margin-right: 6px;
    }
    &__icon {
      @include domSize(12px, 12px);
      background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon_plus.svg") no-repeat;
      background-size: 100%  100%;
    }
  }
  .plan-footer {
    position: fixed;
    width: calc(100% - 64px);
    height: 64px;
    bottom: 0;
    right: 0;
    border-top: 1px solid #e7e8ea;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    background: #ffffff;
  }
}
</style>
