<template>
  <Empty class="empty" v-if="isEmpty" text="暂无对比数据" :show-img="true"></Empty>
  <div class="compare" v-else>
    <div class="tips">
      <div class="tips-left">指标参数</div>
      <div class="tips-right">
        <div class="right-item">
          <div class="item-text">高于历史数据</div>
          <div class="item-icon flex-center">
            <img style="width: 8.31px; height: 11px" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon_compare_up.svg" alt="">
          </div>
        </div>
        <div class="right-item">
          <div class="item-text">低于历史数据</div>
          <div class="item-icon flex-center">
            <img style="width: 8.31px; height: 11px" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon_compare_down.svg" alt="">
          </div>
        </div>
        <div class="right-item">
          <div class="item-text">不同于历史数据</div>
          <div class="item-icon flex-center">
            <img style="width: 14px; height: 12.5px" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon_compare_danger.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="grid" >
      <vxe-grid class="reverse-table" v-bind="gridOptions2">
        <template #icon_default="{ items, _columnIndex, _rowIndex }">
          <div style="display: flex; align-items: center">
            {{items[_rowIndex][`col${_columnIndex}`].value}}
            <img style="margin-left: 11px" v-if="items[_rowIndex][`col${_columnIndex}`].trend === 1" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon_compare_danger.svg" />
            <img style="margin-left: 11px" v-else-if="items[_rowIndex][`col${_columnIndex}`].trend === 2" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon_compare_up.svg" />
            <img style="margin-left: 11px" v-else-if="items[_rowIndex][`col${_columnIndex}`].trend === 3" src="https://f-u-system.oss-cn-beijing.aliyuncs.com/icon_compare_down.svg" />
          </div>
        </template>
      </vxe-grid>
    </div>
  </div>
</template>

<script>
import { getTrend } from '@/service/module/followup'
import Empty from '@/components/Empty'
const moment = require('moment')
export default {
  components: {
    Empty
  },
  props: {
    patientId: [Number, String]
  },
  data () {
    return {
      gridOptions2: {
        border: false,
        showOverflow: false,
        showHeader: false,
        columns: [],
        data: [],
        moment,
        height: document.documentElement.clientHeight - 240.5
      },
      isEmpty: false
    }
  },
  created () {
  },
  mounted () {
    this.getData()
    window.onresize = () => {
      this.gridOptions2.height = document.documentElement.clientHeight - 240.5
    }
  },
  methods: {
    async getData () {
      const myColumns = [
        { field: 'visitTime', title: '随访日期' },
        { field: 'batteryVoltage', title: '电压' },
        { field: 'remainingLongevity', title: '寿命' },
        { field: 'mode', title: '模式' },
        { field: 'lowerRate', title: '基本频率' },
        { field: 'totalAP', title: 'AP占比' },
        { field: 'totalVP', title: 'VP占比' },
        { field: 'atrialThreshold', title: 'RA夺获阈值' },
        { field: 'atrialProgrammedSensitivity', title: 'RA感知' },
        { field: 'atrialLeadPacingImpedance', title: 'RA电极导线阻抗' },
        { field: 'rvThreshold', title: 'RV夺获阈值' },
        { field: 'rvProgrammedSensitivity', title: 'RV感知' },
        { field: 'rvLeadPacingImpedance', title: 'RV电极导线阻抗' },
        { field: 'lvThreshold', title: 'LV夺获阈值' },
        { field: 'lvProgrammedSensitivity', title: 'LV感知' },
        { field: 'lvLeadPacingImpedance', title: 'LV电极导线阻抗' }
      ]
      const [err, res] = await this.$to(getTrend({
        patientId: this.patientId
      }))

      if (res && res.code === 200) {
        const basicData = res.data
        const handleData = []
        basicData.forEach(item => {
          const dataItem = {
            visitTime: {
              value: moment.unix(item.visitTime / 1000).format('Y-MM-DD HH:mm:ss'),
              trend: 0
            },
            ...item.params
          }
          handleData.push(dataItem)
        })
        console.log('数据对比：', handleData)
        this.reverseTable2(myColumns, handleData)
        if (handleData.length === 0) {
          this.isEmpty = true
        }
      } else {

      }
      if (err) {
        this.$message.error('网络错误')
        throw new Error(err)
      }
    },
    // 反转函数
    reverseTable2 (columns, list) {
      const buildData = columns.map(column => {
        const item = { col0: column.title }
        list.forEach((row, index) => {
          item[`col${index + 1}`] = row[column.field]
        })
        return item
      })
      const buildColumns = [
        {
          field: 'col0',
          fixed: 'left',
          width: 135,
          slots: {}
        }
      ]
      list.forEach((item, index) => {
        buildColumns.push({
          field: `col${index + 1}`,
          minWidth: 159,
          slots: {
            default: 'icon_default'
          }
        })
      })
      buildColumns.forEach((item, index) => {
        if (index === 1) {
          item.fixed = 'left'
        }
      })
      console.log(buildColumns)
      this.gridOptions2.data = buildData
      this.gridOptions2.columns = buildColumns
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../style/common";
  .compare {
    height: calc(100vh - 194.5px);
    display: flex;
    flex-direction: column;
    padding: 16px;
    overflow: hidden;
    .tips {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 16px;
      height: 32px;
      @include fs (#909399, 14px, 400);
      .tips-right {
        display: flex;
        .right-item {
          width: 159.33px;
          display: flex;
          align-items: center;
          padding-left: 16px;
          .item-text {
            margin-right: 8px;
          }
        }
      }
    }
    ::v-deep .reverse-table .vxe-body--row .vxe-body--column:first-child {
      background-color: #F2F6FC;
      color: #909399;
    }
    ::v-deep .vxe-cell {
      padding-left: 16px;
    }
  }
  .empty {
  margin-top: 120px;
}
</style>
